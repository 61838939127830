<template>
  <div>
    <div class="d-flex">
      <div class="flex-fill"></div>
      <div class="quote-panel customer" v-if="delayRender">
        <div class="d-flex m-4 mt-0 mb-2">
          <div class="pr-3 pb-3" style="width: 100%">
            <field-column :defs="storeFields('customer').filter(f => f)" field-w="60%" label-w="40%"/>
          </div>
        </div>
      </div>
      <div class="flex-fill"></div>
    </div>
    <div class="text-center mt-5" style="position: relative">
    <b-button size="lg" class="quote-nav-button btn-secondary mr-4" @click="$parent.inMemComponent = 'locations'" variant="info">Back</b-button>
    <b-button size="lg" class="quote-nav-button" :disabled="!formValid" variant="primary" @click="next">Continue</b-button>
    </div>
    <div class="exit-link">
      <a @click="$parent.exit">Exit to the main menu</a>
    </div>
  </div>
</template>

<script>
import {quotemix} from '@/lib/quotemix';
import {mapGetters, mapState} from 'vuex';

import {decompressBuildings} from '@/lib/hydration-io';
import {progressModal} from '@/lib/modal-service';
import {portalEvents} from '@/lib/mix';
import {mmddyyyy} from '@/lib/util';
import FieldColumn from '@/components/fields/field-column';
import {AddressDef} from '@/lib/fields/field-typedefs';

import {defaultLiabilitiesByProgramType} from '@/lib/fields/field-constants';
import fields from '@/lib/fields/fields';
import eventbus from '@/lib/eventbus';
import { datadogLogs } from '@datadog/browser-logs';

let locationManager = fields.quoteFields.locations;
let verboseLogging = false;
const logger = (args, consoleMethod = 'log') => {
  if (verboseLogging){
    try {
      if (Array.isArray(args)) {
        console[consoleMethod](...args);
      } else {
        console[consoleMethod](args);
      }
    }catch(ex){
      debugger;
    }
  }
};
const asyncOp = (op, isLastOp) => {
  let resolver;

  let promise = new Promise(res => {
    resolver = res;
  });
  let _complete = false;
  let _skipped = false;
  let running = false;
  const run = (vm) => {
    if (running || _complete || _skipped) {
      console.warn({op, _complete, _skipped, running});
    }
    running = true;
    if (!vm['opTriggerConditions'][op]) {
      _complete = true;
      resolver(_complete);
    }
    vm[op]().then((result) => {
      let {response} = result;
      logger({'asyncOp': op, result, response});
      if (!response) {
        debugger;
      }
      running = false;
      _complete = !response.hasErrors;
      resolver(_complete);

      if (response.hasErrors) {
        progressModal(false);
        promise = new Promise(res => {
          resolver = res;
        });
      }
    });
  };
  return {
    promise,
    op,
    run,
    running,
    _skipped,
    _complete,
    isLastOp,
    complete(vm) {
      _skipped = !vm['opTriggerConditions'][op];

      return _skipped || _complete;
    }
  };
};

export default {
  components: {FieldColumn},
  data: () => {
    return {
      fieldsPage: 'customer',
      focus: null,
      pmList: [],
      pmId: null,
      existingAddress: null,
      cachedLocations: [],
      mailingAddress: null,
      savedLocations: null,
      currentOp: null,
      customerRegistryId: null,
      ops: {
        createCustomerBroker: asyncOp('createCustomerBroker'),
        createPropertyManager: asyncOp('createPropertyManager'),
        initQuote: asyncOp('initQuote'),
        resetDefaultBuilding: asyncOp('resetDefaultBuilding'),
        updateAddress: asyncOp('updateAddress'),
        updateCustomerBroker: asyncOp('updateCustomerBroker'),
        sendToPropStore: asyncOp('sendToPropStore'),
        updatePropertyManager: asyncOp('updatePropertyManager'),
        evalHazards: asyncOp('evalHazards'),
        addGLLocations: asyncOp('addGLLocations'),
        updateLiabilities: asyncOp('updateLiabilities', true)/*,
        updateHurricaneEligibility: asyncOp('updateHurricaneEligibility', true)*/
      }
    };
  },
  computed: {
    ...mapGetters('getQuote', ['enumValObj']),
    ...mapState(['debugOptions']),
    opTriggerConditions() {
      return {
        createCustomerBroker: this.itemVal('customer.brokerCustomer')?.isNew,
        createPropertyManager: this.showPMFields && !this.existingPM,
        initQuote: true,
        sendToPropStore: true,
        updateCustomerBroker: true,
        resetDefaultBuilding: !this.gl,
        updateAddress: true,
        updatePropertyManager: this.showPMFields,
        evalHazards: true,
        addGLLocations: this.gl,
        updateLiabilities: true//,
        //updateHurricaneEligibility: true

      };
    },
    quoteNumber() {
      return this.itemVal('quote', 'quoteNumber') ?? '';
    },
    formValid() {
      //let pmName = this.itemFromChain('customer.propertyManagerName');
      let reqFields = this.storeFields('customer').filter(f => f.isRequired);
      let invalidFields = reqFields.filter(f => !f.isValid);
      return invalidFields.length === 0;
    },
    existingPM() {
      return Boolean(this.showPMFields === true && this.itemVal('customer.propertyManagerId'));
    },
    customerEmail() {
      return this.itemVal('customer.email');
    },
    usePrimary() {
      return !!this.itemVal('customer.usePrimaryLocation');
    },
    existingPMAddress() {
      if (this.existingPM) {
        let pm = this.pmList.find(({value}) => value === this.itemVal('customer.propertyManagerId'));
        if (pm) {
          return pm.address;
        }
      }
      return '';
    },
    agencyCode() {
      const agency = this.itemVal('scope.agency');
      const [, agencyCode] = agency?.split('|') ?? [];
      return agencyCode;
    },
    showPMFields() {
      return this.isType.string(this.itemVal('customer.hasPropertyManager')) &&
          this.itemVal('customer.hasPropertyManager').includes('Site');
    },
    agentUsername() {
      const agentId = this.itemVal('scope.agent');
      return this.itemFromChain('scope.agent')?.vals.find(a => a.key === agentId)?.username;
    },
    initiateQuoteParams() {
      try {
        let {
          scope: {program, product, jurisdiction}, eligibility,
          customer: {careOf, accountName, email, phone}
        } = this.quoteData;
        let address = locationManager.children[0].addressDef.val;
        let agencyCode = this.isAdmin ? this.agencyCode : undefined;
        let agentUsername = this.isAdmin ? this.agentUsername : undefined;
        let questionIds = eligibility.questions?.map(v => v.key);

        let effectiveDate = new Date();
        effectiveDate.setDate(effectiveDate.getDate() + 1);
        if (this.debugOptions.quoteEffectiveOff) {
          effectiveDate.setDate(effectiveDate.getDate() + this.debugOptions.quoteEffectiveOff);
          console.log(`Initializing quote date to ${mmddyyyy(effectiveDate)}`);
        }
        let effective = mmddyyyy(effectiveDate);
        let submissionId = this.quoteData.quote.submission;

        return {
          submissionId, agentUsername,
          agencyCode, program, product, jurisdiction,
          questionIds, effective, accountName,
          careOf, email, address, phone
        };
      } catch (ex) {
        console.warn(ex);
        return null;
      }
    },
    locationsParam() {
      let cachedLocations = this.cachedLocations;
      let primaryLocation = locationManager.children[0];

      let {locationId, locationHazardId} = primaryLocation.dataTree;
      if (locationId && cachedLocations[0]) {
        let {addressId} = primaryLocation.addressDef.val;
        let isMailingAddress = this.itemVal('customer.usePrimaryLocation');

        cachedLocations[0].locationId = locationId;
        cachedLocations[0].locationHazardId = locationHazardId;
        cachedLocations[0].addressId = addressId;
        cachedLocations[0].isMailingAddress = isMailingAddress;
      }
      return cachedLocations;
    },
    apiBaseParams() {
      let {
        policyId, customerId, jurisdictionId, policyCFId,
        policyCommercialCFId, jurisdictionCFId, quoteId, scoredLocations
      } = this.quoteData.quote;
      return {
        policyId, customerId, jurisdictionId, policyCFId,
        policyCommercialCFId, jurisdictionCFId, quoteId, scoredLocations
      };
    },
    quoteStatus(){
      return this.itemVal('quote', 'status');
    }
  },
  beforeDestroy() {
    progressModal(false);
  },
  mounted() {
    //store the locations from prev step so they aren't clobbered
    // by initQuote->rehydrate
    this.cachedLocations = locationManager.children.map(loc => {
      let {locationId, locationHazardId} = loc.dataTree;
      let {street1, city, zipcode, stateCode, addressId} = loc.addressDef.val;
      let state = stateCode;
      let isMailingAddress = false;
      let addressObj = loc.addressObj;
      return {
        street1, city, state, zipcode,
        addressId, locationId, locationHazardId, isMailingAddress,
        addressObj
      };
    });
  },
  methods: {

    next() {
      if (!this.isMounted){//declined redirect or...?
        return console.log('ignoring further api calls from unmounted component');
      }
      let nextOp = this.nextOp();

      this.currentOp = nextOp.op;
      let {isLastOp} = nextOp;

      nextOp.promise.then((complete) => {
        logger({complete, isLastOp});
        if (complete) {
          if (isLastOp) {
            progressModal(false);
            this.$router.push({
              name: 'getQuote',
              params: {
                step: 'details',
                quoteId: this.apiBaseParams.quoteId
              }
            });
            return;
          }
          this.next();
        }
      });
      nextOp.run(this);
    },
    nextOp() {
      let operations = Object.values(this.ops);
      let i = 0;
      while (i < operations.length - 1) {
        let o = operations[i];

        if (!o.complete(this)) {
          logger({nextOpFound: o.op});
          return o;
        }else{
          logger({skippedOrComplete: o.op});
        }
        i++;
      }

      return operations[operations.length - 1];
    },
    async createCustomerBroker (){
      let {city, stateCode} = this.initiateQuoteParams.address;
      let params = {
        name: this.itemVal('customer.accountName'),
        city, state: stateCode,
        partnerId: `${this.itemVal('scope.partnerId') ?? this.rootPartnerId}`
      };
      let promise = this.lioApi('createCustomerBroker', null, params);
      promise.then(({response}) => {
        this.customerRegistryId = response.id;
        this.updateField({chain: 'customer.customerRegistryId', val: response.id});
      });
      return promise;
    },
    async updateCustomerBroker (){

      let params = {
        customerRegistryId: this.itemVal('customer', 'customerRegistryId') ?? this.customerRegistryId,
        quoteId: this.itemVal('quote', 'quoteId'),
        partnerId: `${this.itemVal('scope.partnerId') ?? this.rootPartnerId}`
      };
      return this.lioApi('updateCustomerBroker', null, params);

    },
    initQuote() {
      let iqp = this.initiateQuoteParams;
      let meta = ['quoteNumber', 'customerId', 'policyId', 'jurisdictionId', 'locked', 'quoteId'];
      meta.forEach(key => {
        this.updateField({chain: `quote.${key}`, val: '...'});
      });
      this.mailingAddress = this.usePrimary ?
          iqp.address :
          {...this.itemVal('customer.address')};
      progressModal('Initializing New Quote', 'Please wait...');
      let promise = this.oneShield('initiateQuote', iqp, {rehydrate: 'force'});
      promise.then(() => {
        eventbus.setActiveQuote(this.itemVal('quote.quoteId'));
        Object.entries(this.mailingAddress).forEach(([prop, val]) =>
            this.updateField({chain: `customer.address.${prop}`, val})
        );
        this.clickStreamEvent(portalEvents.QuoteInitiate, null, this.itemVal('quote.quoteId'));
        const log = {
          quoteNumber: this.itemVal('quote.quoteNumber'),
          quoteId: this.itemVal('quote.quoteId'),
          customerName: this.itemVal('customer.accountName'),
          customerId: this.itemVal('quote.customerId'),
          propertyManager: this.itemVal('customer.propertyManager')?.text,
          producer: this.oktaUser.name
        };
        datadogLogs.logger.info(`Initialized quote: ${log.quoteNumber}`, log);
      });
      return promise;
    },
    async createPropertyManager() {
      progressModal('Creating Property Manager', 'Please wait...');
      const companyName = this.itemVal('customer.propertyManager')?.text;
      const address = this.itemFromChain('customer.propertyManagerAddress').addressObj;
      let promise = this.oneShield('createPropertyManager', {companyName, address});
      promise.then(({response}) => {
        const {chain, val} = response.fields[0];
        this.updateField({chain, val});
      });
      datadogLogs.logger.info(`Created new property manager: ${companyName}`, { address });
      return promise;
    },
    async sendToPropStore() {
      return new Promise(res => {
        let quoteId = this.quoteData.quote.quoteId;
        let propertyBag = {
          hasPropertyManager: this.itemVal('customer.hasPropertyManager'),
          propertyManagerId: this.itemVal('customer.propertyManagerId'),
          propertyManagerName: this.itemVal('customer.propertyManagerName'),
          propertyManagerAddress: this.itemVal('customer.propertyManagerAddress'),
          propertyManagerDisplayAddress: this.itemVal('customer.propertyManagerDisplayAddress'),
          customerRegistryId: this.itemVal('customer.customerRegistryId'),
          incumbentCarrier: this.itemVal('customer.incumbentCarrier'),
          newBusiness: this.itemVal('customer.newBusiness')
        };
        const promises = [this.propStore(quoteId, propertyBag)];
        if (this.showPMFields) {
          promises.push(this.propStore(`propManager_${propertyBag.propertyManagerId}`, propertyBag));
        }
        Promise.all(promises).then((results) => res(results[0]));
      });
    },
    async resetDefaultBuilding() {
      const locations = this.locations;
      let defaultBuilding = locations[0].buildingList[0];
      defaultBuilding.treeVals = {
        constructionType: 1,
        perilsDeduct: 5000,
        hasSprink: false,
        name: 'Building Type 1'
      };
      let buildingFields = ['constructionType', 'perilsDeduct', 'name', 'hasSprink'];
      let params = {
        ...this.apiBaseParams,
        buildings: decompressBuildings([defaultBuilding], buildingFields, locations)
      };
      return this.oneShield('updateBuildings', params);
    },
    async updateAddress() {
      let {mailingAddress} = this;
      const phoneId = this.itemVal('customer.phoneId');
      const phone = this.itemVal('customer.phone');
      let {quoteId, policyId, customerId} = this.quoteData.quote;
      let params = {phone, phoneId, quoteId, policyId, customerId};
      if (!this.usePrimary) {
        let mainAddress = locationManager.children[0].dataTree.addressDef;
        mainAddress.isBilling = false;
        mainAddress.isMailing = false;
        mainAddress.id = mainAddress.addressId;
        let billingAddress = mailingAddress;
        billingAddress.isPrimary = false;
        billingAddress.isBilling = true;
        params.mainAddress = mainAddress;
        params.billingAddress = billingAddress;
      }

      return this.oneShield('updateCustomerAddress', params, {rehydrate: true});
    },
    async updatePropertyManager() {
      return new Promise(res => {
        if (this.itemVal('customer.hasPropertyManager')?.includes('Site')) {
          let {quoteId, policyId} = this.quoteData.quote;
          let propMgrParams = {
            id: this.itemVal('customer.propertyManagerId'),
            quoteId, policyId,
            locationId: this.locations[0].dataTree.locationId
          };

          this.oneShield('updatePropertyManager', propMgrParams).then(res);
        } else {
          res(this.responseStub({operation: 'noop'}));
        }
      });
    },
    async evalHazards() {
      return new Promise(res => {
        let {quoteId, policyId, customerId, jurisdictionId} = this.apiBaseParams;
        let locations = this.locationsParam;
        let evalLocParams = this.gl ? {
          addresses: locations.map(
              ({city, state, zipcode, street1}) =>
                  ({city, state, zipCode: zipcode, street1}))
        } : {
          IDsAndLocations: {
            jurisdictionId, policyId, customerId,
            transactionId: quoteId,
            locations
          }
        };

        let title = locations.length === 1 ? 'Adding Location' : 'Adding Locations';

        progressModal(title, 'Verifying and adding location to the quote.');
        let operation = `${this.gl ? 'getScored' : 'eval'}Locations`;
        this.oneShield(operation, evalLocParams).then(async evalResult => {
          logger({evalResult});

          let scoredLocations = this.gl ? evalResult.response : evalResult.response.scoredLocations;

          if (!this.gl) {
            const {underwritingTriggers} = evalResult.response;
            this.updateField({
              chain: 'quote.underwritingTriggers',
              val: underwritingTriggers
            });

          }

          // TerrorismTerritory is at the policy level, so grab the first present value
          const terrorismTerritory = scoredLocations?.find(l => l.terrorismTerritory?.id)?.terrorismTerritory.id ?? '-1';
          this.updateField({
            chain: 'quote.scoredLocations',
            val: scoredLocations
          });

          this.updateField({
            chain: 'policy.terrorismTerritory',
            val: terrorismTerritory
          });

          await this.fetchQuoteDetail();

          let mappedScores = this.locations.map(l => l.scores);
          console.log({mappedScores});

          let uw = this.itemVal('quote', 'underwritingReason');
          let reasons = uw ? Array.isArray(uw) ? uw.map(reason => reason.description) : [uw.description] : [];
          if (reasons.length) {// roll error obj instead
            evalResult.response.hasErrors = true;
          }
          res(evalResult);

        }).catch((error) => {
          datadogLogs.logger.error(`Quote ${this.quoteNumber} failed to evaluated Location Hazards`, {errorMessage: error.toString()});
          console.error('Unhandled request error', error);
          res(this.responseStub({operation, error}));
        });
      });
    },
    async addGLLocations() {
      let locations = this.locationsParam;
      let {jurisdictionId, policyId, customerId, quoteId} = this.apiBaseParams;
      let params = {
        jurisdictionId, policyId, customerId, quoteId,
        locations: locations.map((l, i) => {
          let id = l.locationId;
          let CDXFRefLocatedAtRef = `CDXFCustomerAddress_${l.addressId || 'newAddress' + i}`;
          return {id, CDXFRefLocatedAtRef};
        }),
        addresses: locations.map((l, i) => {
          let isPrimary = l.isMailingAddress;
          let id = l.addressId || `newAddress${i}`;
          let {street1, street2, city, state, zipcode} = l;
          return {
            street1, street2, city,
            stateId: AddressDef.getStateObj(state).state, zipcode,
            id, isBilling: isPrimary, isPrimary
          };
        })
      };
      return this.oneShield('addGLLocations', params, {rehydrate: true});

    },
    async updateLiabilities() {
      let liabilityParams = this.liabilityParams;
      const statePfx = ['AZ', 'CA', 'UT', 'GA'].includes(this.findState().code) ? 'AZ' : '';
      const programType = `${statePfx}${this.itemVal('scope.product')}`;

      const defaultLiabilitiesClasses = defaultLiabilitiesByProgramType[programType] ?? [];
      const cfLocationStubs = this.gl ? [] : this.liabilityParams.locations
          .filter(l => !l.liabilityCoverageId)
          .map(l => l.locationId);
      if (cfLocationStubs.length){
        let {policyCFId, policyCommercialCFId, jurisdictionCFId} = this.quoteData.quote;
        liabilityParams = {
          ...liabilityParams,
          policyCFId, policyCommercialCFId, jurisdictionCFId
        };
      }
      const defaultLiabilities = {
        ...liabilityParams,
        cfLocationStubs,
        locations: liabilityParams.locations.map(l => {
          defaultLiabilitiesClasses.forEach(classId => {
            Object.entries(l.liabilityClasses)
                .filter(([glId, checked]) => checked && glId !== `${classId}`)
                .forEach(([glId]) => {

                  l.liabilityClasses[glId] = false;
                });

            l.liabilityClasses[classId] = true;

          });
          return l;
        })
      };

      let promise = this.oneShield('updateLiabilities', defaultLiabilities, {rehydrate: true});
      promise.then(({response}) => {
        let savedLocations = response.fields?.find(f => f.chain === 'locations.locationMeta')?.val ?? [];
        if (!Array.isArray(savedLocations)) {
          savedLocations = [savedLocations];
        }
        this.savedLocations = savedLocations;
        logger({savedLocations}, 'warn');
        /*this.flatFieldList.filter(f => f && f.conditionals)
            .forEach(f => {
              let state = this.fieldState(f, true);
              logger({[f.key]: state});
            });*/
      });
      return promise;
    },
    updateHurricaneEligibility() {
      return new Promise(res => {
        this.delayFn(() => {
          try {
            let locations = this.savedLocations.map(l => ({
              locationId: l.locationId,
              locationHurricaneEligibilityId: l.locationHurricaneEligibilityId,
              costOfCatCapital: 0,
              grossAverageAnnualLoss: 0,
              reinsuranceMargin: 0
            }));
            let {quoteId, policyId, customerId, jurisdictionId} = this.apiBaseParams;
            this.oneShield('updateHurricaneEligibility', {
              quoteId,
              policyId,
              customerId,
              jurisdictionId,
              locations
            }).then(res);
          } catch (ex) {
            res(this.responseStub({error: ex, operation: 'updateHurricaneEligibility'}));
          }
        }, 10);
      });

    }
  },
  mixins: [quotemix],
  name: 'quote-customer',
  watch: {
    pmId(id) {
      if (id?.length) {
        this.propStore(`propManager_${id}`).then(({response}) => {

          console.log({lookupExistingPM: response});
          if (!response.error) {
            let {propertyManagerAddress} = response;
            this.updateField({chain: 'customer.propertyManagerAddress', val: propertyManagerAddress});
          }
        });
      }
    },
    customerEmail(email) {
      const currentProducerEmail = this.isAdmin ? this.agentUsername : this.oktaUser?.email;
      if (email?.toUpperCase() === currentProducerEmail?.toUpperCase()) {
        this.updateField({chain: 'customer.email', props: {validOverride: false, validationTipOverride: 'Cannot use producer email.'}});
      } else {
        this.updateField({chain: 'customer.email', props: {validOverride: undefined}});
      }
    },
    existingPM(existing) {
      let props = {optional: !!existing};
      this.updateField({chain: 'customer.propertyManagerAddress', props});
    },
    showPMFields(show) {
      let props = {optional: !show};
      this.updateField({chain: 'customer.propertyManagerName', props});
      this.updateField({chain: 'customer.pmState', val: this.findState()?.key});
      if (!show) {
        this.updateField({chain: 'customer.propertyManagerAddress', props});
      }
    },
    quoteStatus(status){
      if (status !== 'Incomplete') {
        console.log({unexpectedStatus: status});
        this.$parent.conditionalRender();
        if (this.$parent.render !== true){
          this.$router.push({path: `/quote/${this.itemVal('quote.quoteId')}/details`});
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/get-quote";

.quote-panel.customer {
  width: 1000px;

  .input-row {
    height: 55px;

    label {
      display: block;
      padding-right: 12px;
    }
  }

  .field {
    width: 30%;
    vertical-align: middle;

    &.w2 {
      width: 42%;
    }
  }
}
</style>
