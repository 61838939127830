import {
  brokerDeductibles, hoaDeductibles, liabilityVals,
  outdoorPropType, perilsDeductibles
} from '@/lib/fields/field-constants';
import typeDefs from '@/json/types-list.json';


export const locationFields = [
  'name', 'num', 'addressId', 'locationId', 'cfLocationId', 'scores',
  'liabilityCoverageId', 'classCodes', 'locationHazardId',
  'miscFurnCount', 'rateTier', 'hurricaneTier'
].map(key => ({key, type: 'data'}));

export const exposuresMultiselect = {
  lbl: 'General Liability Class (Select all that apply)',
  tags: 'exposures',
  minSelection: 1, type: 'multi', key: `liabilityClasses`,

  runtimeFilter: true,
  vals: liabilityVals,
  _val: Object.fromEntries(
    liabilityVals.map(({key}) => [key, false])
  )
};

export const outdoorPropTypeList = Object.entries(outdoorPropType).map(([key, lbl]) => {
  return {
    lbl, key: `outdoorType${key}`,
    propertyClass: Number(key) > 2000 ? key : Number(key) === 1001 ? 2001 : 2002, //boats is only outlier
    propertyType: key,
    group: 'outdoorList',
    type: Number(key) > 2000 ? 'data' : 'bool'
  };
});

export const miscOutdoorProp = [
  {
    key: 'valuationType', classList: 'w-100', type: 'select', lbl: 'Valuation Type:', tags: 'col1',
    vals: typeDefs.valuation,
    defaultVal: '3'
  },
  {key: 'causeOfLoss', classList: 'w-50', type: 'select', lbl: 'Cause of Loss', tags: 'col1', vals: typeDefs.causesOfLoss },
  {key: 'inflationGuard', classList: 'w-50', type: 'select', lbl: 'Inflation Guard', tags: 'col1', vals: typeDefs.inflationGuard },
  {key: 'agreedValue', type: 'yn', lbl: 'Agreed Value', tags: 'col2', defaultVal: true },
  {
    key: 'perilsDeduct', type: 'select', lbl: 'All Perils Deductible',
    classList: 'w-100', tags: 'allowUnlisted,col2', defaultVal: 5000, lockable: true,
    vals: {
      $conditional: true,
      $switch: [
        {
          test: {admin: true},
          result: perilsDeductibles
        },
        {
          $and: [
            { chain: 'quote.programCode', eq: 'HOA' },
            { chain: 'is1190::'}
          ],
          result: hoaDeductibles
        }
      ],
      defaultVal: brokerDeductibles
    }

  }, {
    key: 'outdoorBlanketTotal',
    classList: 'w-100', type: 'currency', tags: 'col2',
    optional: {
      $conditional: true,
      $switch: [{
        test: {dataVersion: 3},
        result: true
      }],
      defaultVal: false
    },
    labelTip: {
      $conditional: true,
      $switch: [{
        test: {dataVersion: 3},
        result: 'Boat Storage and Moorage; Bridges, roadways, walkways, patios, paved surfaces; Bulkheads, docks, piers, retaining walls, wharves; Cabana; Gate House; Irrigation System; Lights/Poles; Mailboxes; Pool House; Signs/Monuments; Tot lot/Playground Equipment; etc'
      }],
      defaultVal: '$25,000 Tree and shrubs limit cannot be increased.'
    },
    lbl: {
      $conditional: true,
      $switch: [{
        test: {dataVersion: 3},
        result: 'Blanket Outdoor Property \n(excluding trees, shrubs, and plants)'
      }],
      defaultVal: 'Total Blanket Outdoor Property'
    }
  },
  {
    key: 'treeShrubBlanket', classList: 'w-100', lbl: 'Trees, Shrubs, and Plants (excluding wind)',
    type: 'currency', tags: 'col2',
    active: {
      $conditional: true,
      test: {dataVersion: 3},
      optional: true
    }
  },
  {key: 'describeOther', type: 'text', placeholder: 'Describe Other', classList: 'w-100',
    active: {
      $conditional: true, test: {chain: `sibling::outdoorType1012`, eq: true}
    }
  }
].map(field => {
  field.group = 'miscOutdoor';
  return field;
});
