<template>
  <b-container class="get-quote" fluid>
    <div class="head-bg"></div>
    <div class="d-flex mt-4" style="position:relative;height:88px">
      <logo class="mr-4"/>
      <align-middle><h1>BROKER PORTAL || QUOTES IN PROCESS</h1></align-middle>
    </div>
    <b-row>
      <b-col cols="12" class="p-5 content-column">
        <div class="d-flex mt-1 pt-3 pl-3 table-tab-nav">

          <h3 class="mr-5" :class="{active:selection==='all'}" @click="selection='all'">
            <a>
              All Active
              <b-badge :variant="selection==='all'?'primary':'info'" class="mb-1">{{ all.length }}</b-badge>
            </a>
          </h3>
          <h3 class="mr-5" :class="{active:selection==='incomplete'}" @click="selection='incomplete'">
            <a>
              Incomplete Submissions
              <b-badge  :variant="selection==='incomplete'?'primary':'info'" class="mb-1">{{ incomplete.length }}</b-badge>
            </a>
          </h3>
          <h3 class="mr-5" :class="{active:selection==='open'}" @click="selection='open'">
            <a>
              Ready To Bind
              <b-badge  :variant="selection==='open'?'primary':'info'" class="mb-1">{{ open.length }}</b-badge>
            </a>
          </h3>
          <h3 class="mr-5 flex-fill" :class="{active:selection==='underwriting'}" @click="selection='underwriting'">
            <a>
              Referred to Underwriting
              <b-badge  :variant="selection==='underwriting'?'primary':'info'" class="mb-1">{{ underwriting.length }}</b-badge>
            </a>
          </h3>
          <h3 :class="{active:selection==='canceled'}" @click="selection='canceled'">
            <a>
              Inactive
              <b-badge :variant="selection==='canceled'?'primary':'info'" class="mb-1">{{ canceled.length }}</b-badge>
            </a>
          </h3>
        </div>
        <b-alert variant="danger" :dismissible="true" v-model="hasErrors" class="mt-2">
          Failed to retrieve quotes. <a @click="getList()">Retry</a>
        </b-alert>
        <div class="table-panel">
          <div class="pl-2 mt-4 pt-2 d-flex search-bar">
            <b-input-group style="width:222px;" class="mr-2">
              <b-input-group-prepend>
                <b-button class="search-button">
                  <i class="fas fa-search"></i>
                </b-button>
              </b-input-group-prepend>
              <b-input class="search-input" placeholder="Search" v-model="filterBy"></b-input>
            </b-input-group>
            <b-button id="colfilter"><i class="fas fa-sliders-h"/> </b-button>
            <b-popover target="colfilter" triggers="hover">
              <template #title>Choose Columns</template>
              <b-checkbox v-for="c in listModel" :key="c.key" v-model="c.show" :disabled="disabledCols[selection].includes(c.key)">
                <span :style="{color:lioTheme.gray}" v-if="disabledCols[selection].includes(c.key)">{{titleCased(c.key)}}</span>
                <strong style="color:white" v-else>{{titleCased(c.key)}}</strong>
              </b-checkbox>
            </b-popover>
            <div class="flex-fill">
              <div class="d-flex ml-4">
                <b-check class="date-filter-toggle" v-model="filter.effective.show" switch style="position:relative;top:5px">
                  Effective between
                </b-check>

                <span class="ml-3 date-filter-control" v-if="filter.effective.show">
                  <b-datepicker v-model="filter.effective.after" :max="filter.effective.before"/>
                </span>
                <span class="ml-3" v-if="filter.effective.show" style="position:relative;top:9px">
                  and
                </span>
                <span class="ml-3 date-filter-control" v-if="filter.effective.show">
                  <b-datepicker v-model="filter.effective.before"
                                :min="filter.effective.after"/>
                </span>
                <div class="flex-fill invis">fill</div>
              </div>
            </div>
            <div class="text-right pr-3">
              <b-check class="display-all-toggle" style="position: relative;top:5px;right:4px" switch v-model="showAll"
                       v-b-tooltip.left.hover="`Show quotes generated by anyone in your agency. Uncheck to show only your quotes.`">
                Display All Quotes
              </b-check>
            </div>
            <b-button class="mr-3 refresh-button" variant="info" @click="cached[env] = null;getList(true);" v-b-tooltip.hover="'Reset cache and refresh the list'">
            <i class="fas fa-sync"></i>
          </b-button>
          </div>
          <b-table
            v-if="xformed.length"
            class="mt-0" table-variant="secondary" striped bordered
            id="my-table" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :items="items" :fields="fields" :per-page="perPage"
            :current-page="currentPage" small
            sort-null-last
          >
            <template #table-colgroup="scope">
              <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key==='effective' || field.key==='modified' ? '96px' :
                  field.key==='cancel' ? '88px' :
                  ['accountName'].includes(field.key) ? '150px' :
                  ['package','status', 'underwritingStatus', 'product'].includes(field.key) ? '67%' :
                  ['reasonForReferral','address'].includes(field.key) ? '100%' : '123px' }"
              >
            </template>
            <template #head()="scope">
              <div>{{ scope.label.replace('Num','#') }} <i class="desc fas fa-arrow-down mr-2 pl-1"></i><i class="asc fas pl-1 mr-2 fa-arrow-up"></i> </div>
            </template>
            <template #cell(quoteNum)="data">
              <router-link
                v-if="!['underwriting','canceled'].includes(selection) || isAdmin"
                class="text-primary" v-b-tooltip.right="'View Quote...'"
                :to="openQuoteRoute(data.item)"
              >
                <a
                  @click="clickStreamEvent('QuoteOpen', null, data.item.id);"
                >{{ data.value }}</a>
              </router-link>
              <span v-else>{{data.value}}</span>
            </template>

            <template #cell(effective)="data">
              <span>{{ (data.value).toLocaleDateString() }}</span>
            </template>
            <template #cell(totalPremium)="data">
              <span v-if="data.item.status !== 'Referred To Underwriting'">$ {{ currency(data.value,false,false) }}</span>
              <span v-else>-</span>
            </template>
            <template #cell(modified)="data">
              <span>{{ (data.value).toLocaleDateString() }}</span>
            </template>
            <template #cell(address)="data">

              <div class="ellipsize" v-b-tooltip.hover.left="data.value">{{ data.value }}</div>
            </template>

            <template #cell(status)="data">
              <div class="d-flex">

                <div class="mr-3 ellipsize w-100" v-b-tooltip.hover.left="data.value.replace('Quote','').replace('Cancell','Cancel')">
                  <a v-b-modal.bindPolicy v-if="data.item.readyToBind===true" class="float-right">
                    <b-button size="sm" variant="primary" style="padding:2px 4px" @mousedown="getDetail(data.item.id)">BIND</b-button>
                  </a>{{data.value.replace('Quote','').replace('Cancell','Cancel')}}
                </div>
                <b-button
                    v-if="isAdmin && selection === 'canceled' && data.value !== 'Declined' && data.value !== 'Rejected'"
                    size="sm" variant="primary" style="white-space: nowrap;height:30px"
                    @click="reviveQuote(data.item)">
                  RE-ACTIVATE</b-button>

              </div>
            </template>
            <template #cell(underwritingStatus)="data">
              <div style="display: flex; justify-content: start;">
                <div v-if="isAdmin && data.item.status.toLowerCase().includes('underwriting')">
                  <i class="fas fa-pencil-alt mr-2" style="cursor: pointer" @click="rowData=data"/>
                </div>
                <span class="ellipsize" v-b-tooltip.hover.left="data.value" style="justify-content: start">
                  {{ data.value }}
                </span>
              </div>

            </template>
            <template #cell(reasonForReferral)="data">
              <div class="d-flex w-100" v-if="data.item && data.item.quoteNum && selection==='underwriting'">
                <div class="flex-fill ellipsize" v-b-tooltip.hover.left="data.value">{{data.item.uwDescription}}</div>
                <!-- Hidden until we resolve the usability of these messages -->
                <!-- <b-button v-b-tooltip.hover="'Contact LIO'" size="sm" variant="gray" :style="{border:`solid 1px ${lioTheme.gray}`}" style="max-height: 28px;width:55px;padding-left:0;padding-right:0" @click="contactUnderwriter ={quote:data.item,show:true,saving:false,message: ''}">
                  <message-icon :h="20" :w="44" :css="{margin:'0 -5px 0 0'}"/>
                </b-button> -->
              </div>
            </template>
            <template #cell(package)="data"><div class="ellipsize" v-b-tooltip.hover.left="data.value">{{data.value}}</div> </template>
            <template #cell(product)="data"><div class="ellipsize" v-b-tooltip.hover.left="data.value">{{data.value}}</div> </template>
            <template #cell(accountName)="data"><div class="ellipsize" v-b-tooltip.hover.left="data.value">{{data.value}}</div> </template>
            <template #cell(cancel)="data">
              <div class="text-center">
                <a @click="cancel=data.item" v-if="!['Declined', 'Rejected'].includes(data.item.status) && !data.item.status.includes('Cancel')"><i class="fas fa-trash text-danger"></i></a></div>
            </template>
          </b-table>
          <div class="p-5 mt-4" v-if="!xformed.length">
            <div class="pr-5 pl-5 m-5" v-if="retrieving && !hasErrors">
              <div class="d-flex">
                <div class="w-25"></div>
                <div class="w-50">
                  <progress-bar variant="info" class="mr-5 ml-5">Retrieving Quotes</progress-bar>

                </div>
                <div class="w-25"></div>
              </div>
            </div>

            <div v-else class="pb-5 quoteListFilterPrompts">
              <img class="lioAccentIcon" src="../assets/lio-accent.png" />
              <h2>There aren't any active quotes that match your criteria</h2>
              <div v-if="!showAll">
                <p>Do you want to show all quotes instead?</p>
                <b-check switch v-model="showAll" v-b-tooltip.left.hover="`Show quotes generated by anyone in your agency. Uncheck to show only your quotes.`">
                  Display All Quotes
                </b-check>
              </div>
              <div v-else-if="filter.effective.show">
                <p>Try selecting a wider effective date range:</p>
                <div class="date-filters">
                  <div class="date-filter-control ml-3">
                    <b-datepicker v-model="filter.effective.after" :max="filter.effective.before"/>
                  </div>
                  <span class="ml-3">
                    through
                  </span>
                  <div class="date-filter-control ml-3">
                    <b-datepicker v-model="filter.effective.before" :min="filter.effective.after"/>
                  </div>
                </div>
                <div class="reset-filters">
                  <b-button @click="clearFilters()" >Reset Search Filters</b-button>
                </div>
              </div>
              <div v-else-if="selection!=='all'">
                <p>Do you want to<a :class="{active:selection==='all'}" @click="selection='all'">see all of your active quotes</a>?</p>
              </div>
            </div>

          </div>
          <div class="d-flex page-controls">

            <div class="ml-2 pb-2">
              <b-dropdown :text="`Show ${perPage}`" class="mr-2" v-if="rows > 10">
                <b-dropdown-item v-for="n in [10,15,20,25,50,100,200]" :key="n" @click="perPage=n">{{n}}</b-dropdown-item>
              </b-dropdown>
              Showing {{ startItem }} - {{ (startItem - 1) + Math.min(rows,perPage) }} of {{ rows }}
            </div>
            <div class="flex-fill">
              <div class="d-flex" v-if="retrieving">
                <div class="w-25 text-right"></div>
                <div class="w-50 text-center">
                  <progress-bar v-if="xformed.length"
                      style="max-width:888px" class="compressed mr-5 ml-5" variant="info" :delay="128" :slow="36">
                    Retrieving Quotes
                    <span v-if="listProgress && listProgress.page">
                      {{100 * (listProgress.page)}} - {{100 * (listProgress.page + 1)}} of approx {{(listProgress.total ) * 100}}
                    </span>
                  </progress-bar>
                </div>
                <div class="w-25"></div>
              </div>
            </div>
            <div class="text-right mr-2">
              <b-pagination v-if="rows > perPage"
                  variant="info"
                  pills
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
              ></b-pagination>
            </div>


          </div>
        </div>


      </b-col>
    </b-row>
    <b-modal
      size="lg" title="Bind Summary"  id="bindPolicy"
      cancel-variant="info" cancel-title="CANCEL"
      ok-title="BIND & GENERATE POLICY"
      @ok="$router.push({name:'getQuote', params: {step:'bind-quote', quoteId: openId }})">
      <div v-if="!itemFromChain('quote.quoteNumber').val" class="m-5 p-4">
        <progress-bar class="mb-5">Loading Coverage Data...</progress-bar>
      </div>
      <div class="d-flex mr-3 ml-3" style="height:auto" v-else>
        <div style="width:35%" class="mr-3">
          <cov-summary :premium-panel="true" />
        </div>

        <div style="width:65%;" class="panel pb-0">
          <cov-summary/>
        </div>

      </div>
      <p class="text-center mb-0 mt-3" style="position: relative;top:4px">
        <small style="font-size: 12px">Are you sure you would like to bind this policy? (NOTE: this action cannot be undone)</small>
      </p>
    </b-modal>

    <b-modal id="messageUnderwriter" title="Request Update" v-model="contactUnderwriter.show" header-class="show-close-x">
      <div class="text-center" v-if="contactUnderwriter.quote">
        <p><strong>Quote:</strong> {{ contactUnderwriter.quote.quoteNum }}</p>
        <p><strong>Referral Reason:</strong> {{ contactUnderwriter.quote.reasonForReferral }}</p>
      </div>
      <b-form-group label="Message">
        <b-textarea size="md" max-rows="12" style="height:88px" v-model="contactUnderwriter.message"/>
      </b-form-group>
      <template #modal-footer="{  }">
      <footer class="text-center">
        <b-button size="md" variant="primary"
          :disable="contactUnderwriter.saving" @click="sendUnderwriterMessage"
        >SEND MESSAGE</b-button>
      </footer>
    </template>
    </b-modal>

    <underwriting-status-options :row-data="rowData" :uw-status-options="underwritingStatusOptions" :cleared-option="clearedOption"
      @addItemUWStatusList="addItemUWStatusList" @deleteItemUWStatusList="deleteItemUWStatusList" />
    <cancel-quote :id="cancel.id" :quote-num="cancel.quoteNum" @canceled="cancelQuote"/>
  </b-container>

</template>

<script>
import Logo from '@/components/svgs/logo';
import AlignMiddle from '@/components/util/align-middle';
import {cleanseParams, daysAgo, guid} from '@/lib/util';
import {isType} from '@/lib/mytype';
import ProgressBar from '@/components/util/progress-bar';
import {mapGetters, mapMutations, mapState} from 'vuex';
import * as localForage from 'localforage';
import {quoteCacheVersion, statusSearch} from '@/lib/fields/field-constants';
import CovSummary from '@/components/quote-steps/shared/cov-summary';
import CancelQuote from '@/components/modal/cancel-quote';
import UnderwritingStatusOptions from '@/components/modal/underwriting-status-options';
import eventbus from '@/lib/eventbus';
// import MessageIcon from '@/assets/message-icon';
import {datadogLogs} from '@datadog/browser-logs';
import {portalEvents} from '@/lib/mix';

export default {
  components: {/*MessageIcon,*/ CancelQuote, CovSummary, ProgressBar, AlignMiddle, Logo, UnderwritingStatusOptions},

  data: () => {
    let listModel, sortBy, sortDesc, perPage,
      selection, version, showAll, cached = {}, filter;
    let defaultModel =  {
      quoteId: false,
      quoteNum: true,
      accountName: true,
      source: true,
      modified: true,
      effective: false,
      status: true,
      address: true,
      underwritingStatus: true,

      //readyToBind: true,
      reasonForReferral: true,
      totalPremium: true,
      underwriter: false,
      package: false,
      //product: false,
      createdBy: false,
      producer: false,
      quoteType: false,
      cancel: true
    };
    let curVersion = quoteCacheVersion;
    let validStickyState = false;
    let tableState;


    if (localStorage.getItem('quotelistTable')) {
      tableState = JSON.parse(localStorage.getItem('quotelistTable'));
      version = tableState.version;
      validStickyState = version === curVersion;
    }
    if (validStickyState){
      listModel = Object.fromEntries(Object.entries(defaultModel).map(([col, show]) => {
        return [col, tableState.listModel[col] ?? show];
      }));
      sortBy = tableState.sortBy;
      selection = tableState.selection;
      sortDesc = tableState.sortDesc;
      perPage = tableState.perPage;

      showAll = tableState.showAll;

      cached = tableState.cached;
      filter = tableState.filter;
    }
    if (!validStickyState) {
      sortBy = 'modified';
      sortDesc = true;
      perPage = 15;

      selection = 'all';
      version = curVersion;
      listModel = defaultModel;
      showAll = false;
      filter = {
        effective: {
          show: false,
          after: null,
          before: new Date()
        }
      };
    }
    const columnFormatterByKey = {
      underwritingStatus: 'getUWStatusRow'
    };
    return {
      portalEvents,
      listModel: Object.entries(listModel).map(([key, show]) => {
        return {
          key, show,
          sortable: key !== 'cancel',
          formatter: columnFormatterByKey[key]
        };
      }),
      selection, sortBy, sortDesc,
      perPage, version,
      currentPage: 1,
      viewQuote: {
        title: 'Retrieving Quote Detail...',
        opening: false,
        ready: false,
        missing: []
      },
      showAll, cached,
      filter,
      cancel: {},
      disabledCols: {
        underwriting: ['totalPremium'],
        all: ['underwritingStatus', 'reasonForReferral'],
        incomplete: ['underwritingStatus', 'reasonForReferral'],
        open: ['underwritingStatus', 'reasonForReferral'],
        canceled: ['underwritingStatus', 'reasonForReferral']
      },
      hoverId: null,
      listProgress: {total: null, page: null},
      hasErrors: false,
      filterBy: '',
      guid: guid().toUpperCase(),
      bg: 'white',
      responseCount: 0,
      detailCount: 0,
      batchAtt: {},
      detailAtt: {},
      uwList: [],
      uwStatusList: [],
      uwStatus: null,
      incompList: [],
      cancelList: [],
      openList: [],
      render: true,
      retrieving: false,
      dupField: 'quoteNum',
      openId: null,
      contactUnderwriter: {
        show: false,
        saving: false,
        quote: null,
        message: ''
      },
      dupChoices: {
        quoteNum: 'Quote Number',
        id: 'Quote ID'//,
        //contextId: 'contextObjectId'
      },
      rowData: {},
      underwritingStatusOptions: [],
      clearedOption: {
        id: '999',
        type: 'Cleared',
        subType: ''
      }
    };
  },
  computed: {
    ...mapGetters('getQuote', ['itemFromChain', 'quoteList']),
    ...mapState('getQuote', ['quotes', 'env']),
    columnModel(){
      return this.listModel.filter(({key}) => {
        return !(['cancel', 'source'].includes(key) && !this.isAdmin);
      });
    },
    xformed(){
      let map = q => {
        q.uwDescription = `${q.uwTrigger?.length > 1 ? q.uwTrigger + ': ' : ''}${q.reasonForReferral}`;
        return q;
      };
      let filtered = this.quoteList;
      if (!this.showAll && this.oktaUser){
        filtered = filtered.filter(q => q.producer === this.oktaUser.name);
      }
      if (this.filter.effective.show && this.filter.effective.after){
        let after = new Date(this.filter.effective.after);
        //after.setDate(after.getDate() - 1);
        let before = new Date(this.filter.effective.before);
        before.setDate(before.getDate() + 1);
        filtered = filtered.filter(q => q.effective >= after && q.effective <= before);
      }
      return filtered.map(map);
    },
    stickyTriggers(){
      let {showAll, cached, filter} = this;
      return {showAll, cached, filter};
    },
    curPageTriggers(){
      let {selection, sortBy, sortDesc, perPage} = this;
      return {selection, sortBy, sortDesc, perPage};
    },
    statusDesc(){
      let dict = {'%omplet%': 'Completed and Incomplete',
        'Marked%': 'Marked For Issue',
        '%Bind': 'Pre-Bind',
        '%ancel%': 'Canceled',
        Lapsed: 'Expired'
      };
      return searchString => dict[searchString] || searchString;
    },
    items(){
      let sel = this[this.selection];
      let search = this.filterBy.toLowerCase();
      let filterFields = ['accountName', 'id', 'quoteNum', 'status', 'address', 'producer'];
      let inFilter = item => {
        let found = filterFields.find(filt => {
          try {
            if ((`${item[filt]}`).toLowerCase().includes(search)) {
              return true;
            }
            return null;
          }catch(ex){
            console.log({item, filt});
            return false;
          }
        });
        return !!found;
      };
      return sel ? sel.filter(item => !search.length || inFilter(item)) : this.all;
    },
    incomplete(){
      return this.xformed.filter(q =>
        q.status === 'Incomplete' || q.status === 'Rated' || q.status === 'Completed' || q.status.includes('- Inc'));
    },
    canceled(){
      return this.filterList(['Rej', 'Declined', 'Cancel', 'Laps']);
    },
    filterList(){
      return substrings => {
        substrings = substrings.map(s => s.toLowerCase());
        let included = s => substrings.find(sub => s.toLowerCase().includes(sub));
        return this.xformed.filter(q => included(q.status));
      };
    },
    all(){
      return this.filterList(['omplete', 'Bind', 'Rated', 'Marked']);
    },
    underwriting(){
      return this.filterList(['underwriting', 'referral']);
    },
    open(){
      return this.filterList(['Bind', '- Complete']);
    },
    fields(){
      let disabled = this.disabledCols[this.selection];
      return this.columnModel.filter(c => c.show && !disabled.includes(c.key));
    },
    startItem(){
      return Math.max(0, ((this.currentPage - 1) * this.perPage) + 1);
    },
    rows() {
      return this.items.length;
    },
    detailComplete(){
      return Object.values(this.detailAtt).filter(deet => deet.success > 0).length;
    },
    rowsComplete(){
      return Object.values(this.batchAtt).filter(deet => deet.success > 0).length;
    },
    openQuoteRoute(){
      return ({id}) => {
        /*if (status === 'Incomplete'){ // turning into more than one redirection path
          return `/quote/${id}/policy-details`;
        }*/
        return `/quote/${id}/details`;
      };
    },
    uwStatusExists() {
      return (quoteId) => this.uwStatusList?.find(s => s.quoteId === quoteId);
    },
    getUWStatusRow() {
      return (value, key, quote) => {
        return this.getUWStatus([quote?.uwStatusType, quote?.uwStatusSubType, quote?.uwStatusDescription], ' - ');
      };
    }
  },
  methods: {
    ...mapMutations('getQuote', ['rehydrate', 'addQuotes', 'setAny', 'resetSteps']),
    cancelQuote(){
      let q = this.cancel;
      q.modified = new Date();
      q.status = 'Cancelled';
      this.cancel = {};
      this.addQuotes({list: [q], status: 'Cancelled'});
    },
    reviveQuote(q){
      this.msg('RE-ACTIVATE QUOTE', 'Do you want to re-activate this quote?').then(ok => {
        if (ok){
          this.oneShield('reviveQuote', {quoteId: q.id}).then(({response}) => {
            let status = response.fields[0].val;
            q.modified = new Date();
            q.status = status;
            this.addQuotes({list: [q], status});
            console.log({revived: response, status});

          });
        }
      });
    },
    async sendUnderwriterMessage() {
      const { message, quote } = this.contactUnderwriter;
      if (!message) {
        this.contactUnderwriter.show = false;
        return;
      }
      this.contactUnderwriter.saving = true;
      eventbus.$emit('progressToast', 'Sending message to underwriter');
      try {
        await this.oneShield('sendUnderwriterMessage', {quoteId: quote.quoteId, message});
      } catch (e) {
        console.error(e);
      }
      eventbus.$emit('progressToast', false);
      this.contactUnderwriter.saving = false;
      this.contactUnderwriter.show = false;
    },
    addItemUWStatusList(item) {
      this.uwStatusList.push(item);
    },
    deleteItemUWStatusList(item) {
      this.uwStatusList = this.uwStatusList.filter(q => q.quoteId !== item.quoteId);
    },
    aggregateStatuses(status, list) {
      if(status.toLowerCase().includes('underwriting')) {
        list.map(li => {
          const quote = this.uwStatusExists(li.quoteId);
          li.underwritingStatus = this.getUWStatus([quote?.uwStatus?.type, quote?.uwStatus?.subType, quote?.uwStatusDescription]);
          li.uwStatusId = quote?.uwStatusId;
          li.uwStatusDescription = quote?.uwStatusDescription;
          li.uwStatusType = quote?.uwStatus?.type;
          li.uwStatusSubType = quote?.uwStatus?.subType;
          return li;
        });
      }
      return list;
    },
    getList(purgeCache){
      let verbose = 0;
      let errors = false;
      this.hasErrors = false;
      this.listProgress = {page: null, total: null};
      const log = (args, consoleMethod = 'log') => {
        if (verbose){
          try {
            if (Array.isArray(args)) {
              console[consoleMethod](...args);
            } else {
              console[consoleMethod](args);
            }
          }catch(ex){
            debugger;
          }
        }
      };
      if (purgeCache === true){
        localForage.removeItem('quoteList');
        this.setAny({quotes: []});
        this.cached[this.apiEnv] = null;
        this.stickyState();
      }

      let prevDays = !purgeCache && this.cached[this.apiEnv] ?
        Math.max(1, daysAgo(this.cached[this.apiEnv])) :
        90;

      const complete = () => {
        this.retrieving = false;
        log('quote list success');
        if (this.showAll && errors === false){
          this.cached[this.apiEnv] = new Date();
          console.log('Quote list fully synchronized. Cache date reset to today.');
        }
      };

      const onError = (err, pfx = `quoteList-HasErrors`) => {
        this.retrieving = false;
        this.hasErrors = true;
        datadogLogs.logger.warn(pfx, err);
      };

      if (this.$route.name !== 'quotes'){
        log('stop fetching quote lists');
        return;
      }

      this.retrieving = true;

      const eachPage = (result) => {
        listResponse(result, true);
      };

      const listResponse = (result, isCallback = false) => {
        if (this.$route.name !== 'quotes'){
          return;
        }
        let {fields, pageInfo} = result?.response;
        if (!fields && !isCallback){
          console.log('empty result set');
          return complete();
        }
        let batch = {};
        let list = fields.find(f => f.chain === 'quotelist.items').val;

        (Array.isArray(list) ? list : [list]).forEach(q => {
          q.effective = new Date(q.effective);
          q.modified = new Date(q.modified);
          q.accountName = cleanseParams(q.accountName, true);
          q.producer = this.titleCased(q.producer || '');
          q.createdBy = this.titleCased(q.createdBy || '');
          q.readyToBind = q.status === 'Pending Bind';
          q.status = q.status.replace('MarkedForIssue and', 'Marked for Issue -');
          if (q.status.includes('Cancel')){
            q.status = 'Cancelled';
          }
          q.totalPremium = typeof q.totalPremium === 'string' && isType.snum(q.totalPremium) ?
              Number(q.totalPremium.replace(/[^0-9.]/g, '')) :
              q.totalPremium;

          q.cancel = 'c';

          if (!batch[q.status]){
            batch[q.status] = [];
          }
          batch[q.status].push(q);
        });

        Object.entries(batch).forEach(([status, list]) => {
          list = this.aggregateStatuses(status, list);
          this.addQuotes({list, status});
        });

        let {page, total} = pageInfo;
        this.listProgress = {page, total};
        console.log(`Retrieving ${prevDays} days: (page ${Math.min(page, total)} of ${total} total)`, {batchComplete: !isCallback, result});
        if (!isCallback) {
          complete();
        }
      };
      let params = {
        prevDays,
        eachPage,
        // Firstconnect code to only show quotes from producer who created it.
        userOnly: this.oktaUser?.partnerId == 103933
      };

      this.oneShield('getQuoteList', params).then((result) => {
        let {pageInfo} = result.response;
        if (pageInfo.total) {
          log([`page ${pageInfo.page} of ${pageInfo.total}`, {response: result.response}]);
        } else{
          console.log(`no results for past ${prevDays} days`, {response: result.response});
        }
        if (!pageInfo.isCallback){
          listResponse(result, false);
        }
        if (result?.response?.hasErrors){
          onError({params, response: result.response}, `quoteList-HasErrors`);
        }
      }).catch(reason => {
        onError({params, error: reason}, `quoteListError`);
      });
    },
    getDetail(id){
      this.resetSteps();
      this.openId = id;
      this.viewQuote.missing = [];
      this.oneShield('getQuoteDetails', {id, skipGating: true}).then(result => {
        let resultFields = result?.response?.fields.map(f => {
          if (f.type === 'date' && isType.snum(f.val)){
            f.val = new Date(Number(f.val));
          }
          return f;
        });
        this.rehydrate({fields: resultFields, force: true});
        this.viewQuote.ready = true;
      });
    },
    stickyState(){
      let listModel = Object.fromEntries(this.columnModel.map(({key, show}) => [key, show]));
      let quoteListState = {
        version: this.version,
        listModel,
        ...this.curPageTriggers,
        showAll: this.showAll,
        cached: this.cached,
        filter: this.filter
      };

      localStorage.setItem('quotelistTable', JSON.stringify(quoteListState));
    },
    clearFilters(){
      this.filter.effective.show = false;
      this.filterBy = '';
    },
    getUWStatus(args, separator = ' ') {
      return args?.filter(Boolean).join(separator).trim();
    }
  },
  mounted() {
    this.lioApi('underwritingStatusOptions', null, null)
      .then(result => {
        this.underwritingStatusOptions = result?.response;
        this.underwritingStatusOptions.push(this.clearedOption);
      })
      .catch(error => {
        console.error('Error getting uwStatusOptions', error);
      });
    this.oneShield('redButton', {opName: 'getQuoteList', opCode: false});
    this.lioApi('listUnderwritingStatus', null, null).then(result => {
      this.uwStatusList = result?.response;
      this.getList();
    }).catch(result => {
      console.error('Error getting uwStatus', result);
      this.uwStatusList = [];
      this.getList();
    });
    this.setAny({env: this.apiEnv});
    document.title = 'Quote List - LIO Insurance';
  },
  beforeDestroy() {
    this.oneShield('redButton', {opName: 'getQuoteList', opCode: true});
  },
  name: 'Quotes',
  watch: {
    apiEnv(env){
      this.setAny({env});
    },
    stickyTriggers: {
      handler(){
        this.stickyState();
      },
      deep: true
    },
    columnModel: {
      handler() {
        this.stickyState();
      },
      deep: true
    },
    curPageTriggers: {
      handler() {
        this.currentPage = 1;
        this.stickyState();
      },
      deep: true
    },
    currentPage(){
      this.stickyState();
    }
  }
};

</script>

<style  lang="scss">
@import "../assets/scss/variables";
@import "../assets/scss/main";
@import "../assets/scss/modals";
@import "../assets/scss/tables";
h3.popover-header{
  color:white;
}
#app {

  .content-column {
    margin-top:-8px;
    .table-tab-nav {
      h3 {
        color: $navy;
        font-size: 15px;
        &.active {
          color: $primary;
        }

        a .badge {
          font-size: 15px;
          position: relative;
          top: 0px;

          &.badge-info,
          &.badge-primary {
            padding-bottom: 1px;
          }
        }
      }
      margin-bottom: -12px;
    }
    div.search-bar{
      border-bottom:solid 1px $gray;
      padding-bottom: 6px;

      .search-button,
      .search-input,
      #colfilter,
      .refresh-button{
        padding: 0.55rem 0.75rem 0.2rem;
        height: 37px;
      }

      .date-filter-toggle,
      .display-all-toggle {
        padding-top: 0.15rem;
      }

      label {
        padding: 0.15rem 0 0 0;
      }
    }

    div.table-panel {
      .lio-progress.compressed{
        .text-center{
          font-size:13px;
          margin:0;
        }
        .progress-anim{
          margin-top:-6px;
          height:8px;
        }
      }

      background-color: white;
      box-shadow: 0 12px 25px $gray;
      table.b-table{
        table-layout: fixed;
        width:100%;
      }
      div.page-controls {
        height: 47px;
        padding-top: 6px;
        margin-top: -10px;
        border-top: solid 1px $gray;
      }
      .date-filter-control {
        label {
          padding: .55rem .75rem .275rem 0;
        }
      }
      .ellipsize {
        max-height: 28px;
        max-width:100%;
      }

      .quoteListFilterPrompts {
        img.lioAccentIcon {
          height: 75px;
          width: 100px;
          margin: 0 0 0 50%;
          transform: translateX(-50%);
        }
        h2 {
          text-align: center;
          font-size: 1.4rem;
          margin:25px 0 0 0;
        }
        div {

          p {
            font-size: 1.2rem;
            text-align: center;
            margin: 25px 0 0 0;

            a {
              font-size: inherit;
              text-decoration: underline;
              color: #D16A60;
              margin:0 0 0 4px;
            }
          }

          .custom-switch {
            text-align: center;
            margin: 20px 0 0 0;
            padding:2px 0 0 0;

            .custom-control-label {
              padding-top: 2px;
            }
          }

          .date-filters {
            display: inline-block;
            margin: 25px 0 0 50%;
            transform: translateX(-50%);

            .date-filter-control {
              display: inline-block;

              label {
                padding: .55rem .75rem .275rem 0;
              }
            }
          }
          .reset-filters {
            margin-top: 25px;
            text-align: center;

            .btn {
              padding: 0.55rem 0.75rem 0.275rem;
            }
          }
        }
      }
    }
  }
}
</style>
