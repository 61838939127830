
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

import {clone, sortByKey} from '@/lib/util';
import {decompressBuildings} from '@/lib/hydration-io';
import deepEqual from 'deep-equal';
import eventbus from '@/lib/eventbus';
import {progressModal, simpleModal} from '@/lib/modal-service';
import {isType} from '@/lib/mytype';
import {datadogLogs} from '@datadog/browser-logs';
import {lookupError} from '@/lib/error';
import {buildingFields} from '@/lib/fields/building-data';

export const detailsTab = {
  data: () => {
    return {
      apiSaveFunction: 'updatePolicyDetails',
      quoteFields: ['quoteId'],
      tabName: 'policy',
      originalVals: null
    };
  },
  computed: {
    ...mapGetters('getQuote', ['storeFields', 'hasChanges']),

    apiParams(){
      let addlParams = this.additionalParams || {};
      let kvs = this.storeFields(this.tabName).map(f => {
        if (f.dirty && f.isNumeric && !f.val){
          return [f.key, '0'];
        }
        if (f.isUnlistedVal && f.hasTag('allowUnlisted') && f.val !== '') {
          // Ignore a value the user doesn't have access to when allowUnlisted
          // Except '' which means clear
          return [f.key, null];
        }
        return [f.key, f.val];
      });
      let params = Object.fromEntries(kvs);
      let quoteFields = Object.fromEntries(
        this.quoteFields.map(key => [key, this.quoteData.quote[key]])
      );
      return {...quoteFields, ...params, ...addlParams};
    },
    dirty(){
      if(['policy', 'baseline'].includes(this.tabName)){
        return this.hasChanges({group: this.tabName, requireTouch: true}).length > 0;
      }
      let apiEntries = Object.entries(clone(this.apiParams))
        .map(([k, v]) => {
          if (isType.date(v)){
            v = v.toJSON();
          }
          return [k, v];
        });


      return !deepEqual(this.originalVals, Object.fromEntries(apiEntries));
    }
  },
  methods: {
    ...mapMutations('getQuote', ['rehydrate']),
    cleanSlate(){
      let apiEntries = Object.entries(clone(this.apiParams))
        .map(([k, v]) => {
          if (isType.date(v)){
            v = v.toJSON();
          }
          return [k, v];
        });
      this.originalVals = Object.fromEntries(apiEntries);
    },
    save(){
      if (this.dirty) {
        let tab = this.tabName === 'baseline' ? 'Liability' : this.tabName;
        const toast = `Saving ${this.titleCased(tab)}`;
        let options = {savingFlag: true, toast, rehydrate: true};
        return this.oneShield(this.apiSaveFunction, this.apiParams, options);

      }
      return Promise.resolve('unchanged');
    }
  },
  mounted(){
    this.cleanSlate();
    eventbus.$on('quoteRehydrate', this.cleanSlate);
  }
};

export const quotemix = {
  data(){
    return {
      isMounted: false,
      delayRender: false,
      //chains: null,
      fieldsPage: null
    };
  },
  computed: {
    ...mapState('getQuote', ['locationIndex', 'buildingTypeIndex', 'navigationPath']),
    ...mapGetters('getQuote', [
      'locations', 'selectedLocation', 'itemVal', 'buildings', 'itemFromChain', 'quoteData', 'findState',
      'invalidCount', 'storeFields', 'requiredInPage', 'hasChanges', 'evalCondition', 'computedVal', 'flatFieldList', 'flatFieldsObject'
    ]),
    quoteNumber() {
      return this.itemVal('quote', 'quoteNumber') ?? '';
    },
    curPage(){
      return this.navigationPath?.length && this.navigationPath[this.navigationPath.length - 1];
    },
    prevPage(){
      return this.navigationPath?.length && this.navigationPath[this.navigationPath.length - 2];
    },
    gl(){
      return this.itemVal('quote.isGl') || false;
    },
    page(){
      return this.$route.params.step;
    },
    quoteLocked(){
      return !this.isAdmin && this.itemVal('quote.quoteLocked') === true;
    },
    wsLossMitigationState(){
      let enabled = Boolean(this.flatFieldList.find(f => f.key === 'wsLossMitigation' && f.val === true));
      if (enabled){
        return this.findState()?.code;
      }
      return false;
    },
    liabilityParams(){
      try {
        const {quoteId, policyId, policyCommercialGLId, policyGLId, jurisdictionGLId } = this.quoteData.quote;
        const {jurisdiction} = this.quoteData.scope;

        let locations = Object.values(this.locations.filter(l => l.isValid)).map(loc => {

          let {liabilityClasses, liabilityCoverageId, classCodes, locationId} = loc.dataTree;
          let premOpsTerritory;
          if (loc.scores) {
            premOpsTerritory = loc.scores.premOpsTerritory.id;
          }
          let exposureFiltered = loc.children
            .filter(c => c.exposure && !this.isType.nullOrUndef(c.val))
            .map(({key, val}) => [key, val]);
          let exposureFields = Object.fromEntries(exposureFiltered);
          return {
            chain: loc.chain,
            liabilityClasses, liabilityCoverageId, classCodes, locationId, premOpsTerritory,
            ...exposureFields
          };
        });
        return {
          quoteId, jurisdiction, policyId, policyCommercialGLId, policyGLId, jurisdictionGLId, locations
        };
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    iceDammingUpdateVal(){

      let deductMutations = this.flatFieldList
        .filter(f => f.key === 'perilsDeduct' && f.dirty && f.isValid);
      if (deductMutations.length){
        let iceDamming = this.flatFieldsObject['propertyCoverages.iceDamming']?.val ?? 0;
        let maxDeduct = Math.max(...deductMutations.map(f => Number(f.val)));
        if(iceDamming < maxDeduct){
          console.log({updateIceDamming: {from: iceDamming, to: maxDeduct}});
          return maxDeduct;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions('getQuote', ['validateStepReady', 'getQuoteDetail']),
    ...mapMutations('getQuote',
      ['setAny', 'setValid', 'setChained', 'initStep', 'updateField', 'rehydrate', 'removeDef']),
    ...mapMutations(['setRoot']),
    fieldState(def, headless){
      let vm = this;
      if (def.conditionalProps){
        let vals = def.conditionalProps.filter(cp => !headless || !cp.skipHeadless).map(cp => {
          if (headless && def.conditionals?.active){
            def.conditionals.testedHeadless = true;//prevent endless ankle-biting
          }
          if (cp.runOnceIsComplete === true){
            return 'complete';
          }
          cp.vm = vm;
          const getResult = (r) => {
            const computed = result => isType.object(result) && result.$compute ?
              this.computedVal(result.$compute, def) : result;
            if (isType.bool(r)){
              if (r && cp.result !== undefined){
                return computed(cp.result);
              }else if (!r && cp.defaultVal !== null){
                return computed(cp.defaultVal);
              }
              return r;
            }

            return computed(r);
          };
          const itemTest = (caseItem) => this.evalCondition({vm, def: cp.def, test: (caseItem?.test ?? caseItem)});
          if (Array.isArray(cp.$switch) /*|| Array.isArray(cp.$and) || Array.isArray(cp.$or)*/) {
            if (Array.isArray(cp.$switch)) {
              let found = cp.$switch.find(itemTest)?.result;
              if (!found) {
                found = cp.defaultVal;
              }
              let result = getResult(found);
              return cp.updateFunction(result);
            }
          }
          else if (cp.$compute){
            let computed = this.computedVal(cp.$compute, def);
            return cp.updateFunction(computed);
          }

          let result = this.evalCondition(cp);
          result = getResult(result);
          return cp.updateCondition(result);
        });
        return vals.map(v => JSON.stringify(v));
      }
      return null;
    },
    async fetchQuoteDetail(id, opts){
      let filter;
      if (opts) {
        filter = opts.filter;
      }
      return this.getQuoteDetail({id, oneShield: this.oneShield, filter});
    },
    async getRatingStatus(jobId, quoteId, attemptCount = 1){
      progressModal('Rating and Underwriting Your Quote');
      this.setAny({isRating: true, saving: true});
      const {response} = await this.oneShield('getJobStatus', {jobId, quoteId});
      if (response.hasErrors){
        return this.onRatingFinish(quoteId, 'error', response.error);
      }
      const status = response?.fields.find(f => f.key === 'jobStatus')?.val;
      if (status.id !== '3' && status.id !== '4') {
        const delayMs = Math.min(10000, attemptCount * 1500);
        console.log(`Rating still pending (${status?.label}), trying again in ${delayMs}ms`);
        this.setRoot({sessionStart: Date.now()});
        return new Promise((res) => {
          setTimeout(() => {
            res(this.getRatingStatus(jobId, quoteId, attemptCount + 1));
          }, delayMs);
        });
      }
      await this.onRatingFinish(quoteId, status, false);
      return status;
    },
    findBrokerCustomer(text, debounceDelay, callback){
      if (!text.length) {
        return [];
      }
      let name = `?name=${text}`;
      const getList = ({response}) => {
        let list = [];
        if (Array.isArray(response)) {

          list = response.map((item) => {
            let partnerId = this.itemVal('scope.partnerId');
            let partnerConflict = item.partnerId && `${partnerId}` !== `${item.partnerId}`;
            let subTitle = `${item.city}, ${item.state}`;
            return {
              subTitle,
              text: item.name, partnerConflict,
              value: item.id,
              ...item
            };
          });
          list.push({text, subTitle: 'Create New...', isNew: true, value: 'new_' + text});
          list = sortByKey(list, 'text');
        }
        if (callback){
          callback(list);
        }
      };
      let operation = 'findBroker';
      let props = {name};
      this.apiBouncer({operation, props}, debounceDelay, getList);

    },
    brokerCustomerChosen(item, componentVm){
      this.updateField({chain: 'customer.accountName', val: item.text});
      this.updateField({chain: 'customer.customerRegistryId', val: item.id});
      if (item.isNew){
        return;
      }

      if (item.partnerConflict){
        const btns = {
          ok: 'Change Customer',
          cancel: 'Exit'
        };
        simpleModal('Account Already Assigned', 'Another Broker is assigned to this account. For questions please contact 877.546.7155 or service@lioinsurance.com', btns).then(ok => {
          componentVm.clearInput();
          if (!ok){
            this.$router.push({path: '/'});
          }
        });
      }else if (item.quoteId){
        const btns = {
          ok: 'View Quote',
          cancel: 'Change customer'
        };
        simpleModal('Existing Quote Found', `Customer (${item.text}) already has an active quote. Open it?`, btns).then(ok => {
          componentVm.clearInput();
          if (ok) {
            window.location.assign(`/quote/${item.quoteId}/details`);
          } else {
            componentVm.clearInput();
          }
        });
      }
    },
    checkFSIB(item, componentVm, def){
      return new Promise(res => {
      if (!isType.object(item) || !item.name){
        return;
      }
      const blockFS = () => {
        const btns = {
          ok: 'Change Selection',
          cancel: 'Exit'
        };
        const title = 'FirstService Eligibility';
        const message = 'LIO is unable to quote this account with your brokerage. For questions please contact 877.546.7155 or service@lioinsurance.com';
        simpleModal(title, message, btns).then(ok => {
          if (ok){
            componentVm.clearInput();
          }else{
            this.$router.push({path: `/`});
          }
        });
      };

      console.log({checkFSIB: item, componentVm, def});

      const {name} = item;
      if (name.startsWith('First Service') || name.startsWith('FirstService')){
        const partnerId = this.itemVal('scope.partnerId');
        this.lioApi('fsbLookup', null, {partnerId}, false).then(({response}) => {
          let result = true;
          if (isType.object(response) && response.allowedBroker){
            console.log({firstServiceAllowed: true});
          }
          else {
            result = false;
            blockFS();
          }
          res(result);
        });
      }
      });
    },
    async onRatingFinish(quoteId, status, error){
      await this.oneShield('getObjectLock', {contextId: quoteId});

      this.setAny({isRating: false, saving: false});
      await this.propStore(quoteId, {ratingJobId: null});
      if (error){
        console.warn({ratingFinish: error});
      }else{
        console.log('Rating Complete', {ratingComplete: status});
      }
      eventbus.$emit('ratingFinish', {status, error});
      return {status, error};
    },
    async rateQuote(){
      const ratingStartTime = performance.now();
      const quoteStatusPreRate = this.itemVal('quote.status');
      let { quoteId, ratingJobId, quoteNumber } = this.quoteData.quote;
      if (quoteStatusPreRate === 'Incomplete') {
        datadogLogs.logger.error(`Attempting to rate Incomplete quote ${this.quoteNumber}`, {
          quoteId,
          quoteNumber: this.quoteNumber,
          sessionId: this.sessionId
        });
        // TODO: Block user with more explicit warning, this will always fail
      }
      this.setAny({isRating: true, saving: true});
      await this.oneShield('releaseObjectLock', {contextId: quoteId});
      let response;
      let ratingStatus;
      if (!ratingJobId) {
        let result = await this.oneShield('rateQuote', {quoteId});
        response = result?.response;
        console.log({rateQuote: response});
        ratingJobId = response?.fields.find(f => f.key === 'jobId')?.val;
      }
      if(ratingJobId) {
        this.propStore(quoteId, {ratingJobId});
        ratingStatus = await this.getRatingStatus(ratingJobId, quoteId);
        if(ratingStatus?.id === '3') {
          await this.fetchQuoteDetail();
        }
      }
      else {
        return this.onRatingFinish(quoteId, 'error', {missingJob: true, response});
      }
      const quoteStatusPostRate = this.itemVal('quote.status');
      const referralReasonRaw = this.itemVal('quote.underwritingReason');
      const referralReasons = Array.isArray(referralReasonRaw) ? referralReasonRaw.map(r => r?.description) : [referralReasonRaw?.description];
      const uwTriggered = quoteStatusPreRate !== quoteStatusPostRate && quoteStatusPostRate === 'Referred To Underwriting' ? 'true' : 'false';
      const ratingError = ratingStatus?.status === 'error' ? 'true' : 'false';
      const statusMessage = ratingError === 'true' ? 'Error' : 'Success';
      const ratingEndTime = performance.now();
      const elapsedTimeMs = (ratingEndTime - ratingStartTime).toFixed(2);
      const log = {
        quoteNumber: quoteNumber,
        quoteStatusPreRate: quoteStatusPreRate,
        quoteStatusPostRate: quoteStatusPostRate,
        uwTriggered: uwTriggered,
        referralReasons,
        ratingError: ratingError,
        buildings: this.buildings.length,
        sessionId: this.sessionId,
        elapsedTimeMs: elapsedTimeMs
      };
      datadogLogs.logger.info(`Quote: ${quoteNumber} completed rating with ${statusMessage} in: ${elapsedTimeMs}ms`, log);
    },
    async setPolicyNeedsUnderwritingFlag(value = true, options = {toast: 'Saving quote progress'}){
      const { quoteId, policyId } = this.quoteData.quote;
      const { response } = await this.oneShield('setPolicyUnderwritingFlag', {
        quoteId,
        policyId,
        policyNeedsInformation: value
      }, { savingFlag: true, ...options});
      const status = response.fields.find(f => f.key === 'status')?.val;
      const needsUnderwritingInfo = response.fields.find(f => f.key === 'policyNeedsUnderwritingInformation')?.val;
      this.updateField({chain: 'quote.status', val: status});
      this.updateField({chain: 'quote.policyNeedsUnderwritingInformation', val: needsUnderwritingInfo});
    },
    async saveBuildingsByGroup(group, bypassRehydrate = false) {
      let {
        policyId, customerId, jurisdictionId, policyCFId,
        policyCommercialCFId, jurisdictionCFId, quoteId
      } = this.quoteData.quote;
      const sendAll = this.itemFromChain('buildingFields.identicalCt').dirty;
      let buildingVals;

      let buildings = this.locations.map(l =>
        l.buildingsDef.children.filter(b => b?.children)).flat();
      let sewerDischargeValues = this.dataVersion >= 3 || this.itemVal('quote.sewerDischargeExcludedBuildings') !== null ?
        buildings.map(b => {
          let f = b.child('sewerDischarge');
          if (!f.hasTag('hasExternalSource')){
            f.tagList.push('hasExternalSource');
          }
          return [f.chain, f._val];
        }) : null;
      if (sewerDischargeValues){
        sewerDischargeValues = Object.fromEntries(sewerDischargeValues);
        this.propStore(quoteId, {sewerDischargeValues});
        //need to set this so rehydrate can rebuild the new overrides
        this.updateField({chain: 'quote.sewerDischargeExcludedBuildings', val: sewerDischargeValues});
      }
      if (!sendAll){
        let dirtyKeys = {};
        buildings.forEach(b => {
          b.children.forEach(c => {
            if (c.dirty || c.key === 'buildingLimit') { // Always include Limit for Sewer Override Calc
              dirtyKeys[c.key] = true;
            }
          });
        });
        buildingVals = Object.keys(dirtyKeys);
      }else{
        buildingVals = buildingFields.map(f => f.key);
      }
      let locationLocationHazards = {};
      if (Object.values(this.quoteData.locations).some(l => l.scores)) {
        const locationDetails = Object.values(this.quoteData.locations).map(l => ({ id: l.locationId, locationHazardId: l.locationHazardId, scores: l.scores }));
        locationLocationHazards = Object.fromEntries(locationDetails.map(l => [l.id, { locationHazardId: l.locationHazardId, ...l.scores }])) ?? {};
      }

      let params = {
        policyId, customerId, jurisdictionId, policyCFId,
        policyCommercialCFId, jurisdictionCFId, quoteId,
        locationLocationHazards,
        buildings: decompressBuildings(buildings, buildingVals), iceDamming: this.iceDammingUpdateVal
      };
      console.log({saveBuildingsParams: params});
      let result = await this.oneShield('updateBuildings', params, { savingFlag: true, toast: 'Saving Building Details'});
      if (!bypassRehydrate) {
        this.rehydrate({fields: result.response.fields, page: 'buildings'});
      }
      return result;
    },
    async getRateTier(){
      let scoreField = (id, key) => {

        try {
          let loc = this.itemVal('quote.scoredLocations').find(l => l.locationId === id);
          let val = loc[key] || null;
          if (val['id']) {
            return Number(val.id);
          }
          return val;
        }catch(ex){
          return null;
        }
      };
      let locations = this.locations.map(location => {
        let {locationId, hurricaneTier, addressDef: {stateCode}} = location.dataTree;
        let buildings = this.gl ?
          [] : this.buildings.filter(b => b.li === location.li && !b.dataTree._isPseudo)
            .map(({ dataTree: { buildingId, yearBuilt, floorCt, constructionType } }) => {
              return {
                buildingId, yearBuilt,
                numberOfStories: floorCt,
                constructionType
              };
            });
        const isOutdoorPropertyOnly = this.buildings.filter(b => b.li === location.li).every(b => b.dataTree._isPseudo);
        return {
          locationId: locationId,
          isOutdoorPropertyOnly,
          hurricaneTier,
          state: stateCode,
          fireProtectionClass: scoreField(locationId, 'ppc'),
          wildfireRisk: scoreField(locationId, 'wildfireScore'),
          freezeRisk: scoreField(locationId, 'freezeScore') === 1,
          convectiveStormRisk: Math.max(
            scoreField(locationId, 'straightLineWindScore'),
            scoreField(locationId, 'hailScore'),
            scoreField(locationId, 'tornadoScore')
          ),
          buildings
        };
      });
      let params = {
        quoteId: 'quote.quoteId',
        productType: 'quote.programCode',
        riskTransferForContractors: 'underwriting.contractorsCarryGL',
        propertyManager: 'customer.hasPropertyManager',
        effectiveDate: 'policy.effective',
        priorLosses: 'policy.totalLosses',
        expiringPremium: 'policy.expiringPremium'
      };
      let pEntries = Object.entries(params)
        .map(([key, chain]) => [key, this.itemVal(chain)]);
      params = Object.fromEntries([['locations', locations], ...pEntries]);
      return this.oneShield('getRateTier', params);
    },
    getOtherReferralDetails() {
      const { programCode, isGl } = this.quoteData.quote;
      const locations = this.quoteData.locations;

      const yearsBuilt = this.flatFieldList.filter(f => f.key === 'yearBuilt' && !f.selectionProxy && f.val).map(f => f.val);
      const buildingLimits = this.flatFieldList.filter(f => f.key === 'buildingLimit' && !f.selectionProxy && f.val).map(f => f.val);
      const assocByLawsCoverage = this.itemVal('propertyCoverages.assocByLawsCov');
      const ppcValues = this.flatFieldList.filter(f => f.key === 'scores' && f.val).map(f => f.val.ppc?.value);
      const crimeScores = this.flatFieldList.filter(f => f.key === 'scores' && f.val).map(f => f.val.crime?.value);
      const states = this.flatFieldList.filter(f => f.key === 'scores' && f.val).map(f => f.val.state);
      const locationUnitCounts = Object.entries(locations).map(([name, loc]) => ({ name: name, unitCt: loc.unitCt, unitCtOwnerOccupied: loc.unitCtOwnerOccupied }));
      const isHOA = programCode === 'HOA';
      const hoaReferredStates = ['AZ', 'TX'];
      const isHOAReferredState = hoaReferredStates.some(referredState => states.includes(referredState));


      const referForYearsBuilt = yearsBuilt.some(year => year !== '' && !isType.nullOrUndef(year) && year < 1990);
      const referForHighBuildingLimit = buildingLimits.some(limit => limit !== '' && !isType.nullOrUndef(limit) && limit > 4_000_000);
      const referByAssocByLaws = assocByLawsCoverage?.toString() === '3';
      const referForPPCValues = ppcValues.some(ppc => ppc.includes('9') || ppc.includes('10'));
      const referForCrimeScores = crimeScores.some(cs => cs > 130);
      const referForRentalExposure = locationUnitCounts.some(location => location.unitCt && location.unitCtOwnerOccupied && Number(location.unitCtOwnerOccupied) * 2 < Number(location.unitCt));
      const referForCOA = programCode === 'COA';
      const referForGl = isGl;
      const referForHOAInSpecifiedStates = isHOA && isHOAReferredState;
      
      const hasOtherReferral = referForGl || referForYearsBuilt || referForHighBuildingLimit || referByAssocByLaws || referForPPCValues || referForCrimeScores || referForRentalExposure || referForCOA || referForHOAInSpecifiedStates;
      
      return {
        yearsBuilt,
        buildingLimits,
        assocByLawsCoverage,
        ppcValues,
        crimeScores,
        states,
        isHOA,
        isGl,
        locationUnitCounts,
        referForGl,
        referForYearsBuilt,
        referForHighBuildingLimit,
        referByAssocByLaws,
        referForPPCValues,
        referForCrimeScores,
        referForRentalExposure,
        referForCOA,
        referForHOAInSpecifiedStates,
        hasOtherReferral
      };
    },
    async updateRateTier( { includeOtherFlag } = {}) {
      let {response} = await this.getRateTier();
      console.log({rateTierLocations: response.locations});
      const {
        quoteId, policyId, quoteNumber, isGl,
        policyCommercialGLId, policyGLId, jurisdictionGLId,
        policyCommercialCFId, policyCFId, jurisdictionCFId
      } = this.quoteData.quote;
      

      const locationTier = (locationId) => {
        try {
          let tier = response.locations.find(l => l.locationId === locationId)?.rateTier;
          if (!isType.snum(Number(tier))) {
            datadogLogs.logger.error(
              `Error getting location rate tier for quote ${this.quoteNumber}`, {locationId, quoteId, rateTierResponse: response}
            );
            tier = 2;
          }
          return tier;
        } catch (error) {
          datadogLogs.logger.error(error);
          datadogLogs.logger.error(
              `Error getting location rate tier for quote ${this.quoteNumber}`, {locationId, quoteId, rateTierResponse: response, errorMessage: error.toString()}
            );
          return 2;
        }
      };
      let otherFlag = undefined;
      let otherReferralDetails = undefined;
      if (includeOtherFlag) {
        const {
          yearsBuilt,
          buildingLimits,
          assocByLawsCoverage,
          ppcValues,
          crimeScores,
          states,
          isGl,
          isHOA,
          locationUnitCounts,
          referForGl,
          referForYearsBuilt,
          referForHighBuildingLimit,
          referByAssocByLaws,
          referForPPCValues,
          referForCrimeScores,
          referForRentalExposure,
          referForCOA,
          referForHOAInSpecifiedStates,
          hasOtherReferral
        } = this.getOtherReferralDetails();
        if (hasOtherReferral) {
          otherFlag = true;
        } else {
          otherFlag = false;
        }
        otherReferralDetails = {
          otherFlag,
          yearsBuilt, buildingLimits, assocByLawsCoverage, ppcValues, crimeScores, states, isGl, isHOA, locationUnitCounts,
          referForYearsBuilt, referForHighBuildingLimit, referByAssocByLaws, referForPPCValues, referForCrimeScores,
          referForRentalExposure, referForCOA, referForGl, referForHOAInSpecifiedStates
        };
        datadogLogs.logger.info(`Setting OTHER referral flag for quote ${quoteNumber} to ${otherFlag}`, {
          otherReferralDetails
        });
      }
      const locations = this.locations.map(
        ({dataTree: {cfLocationId, liabilityCoverageId, locationId}}) =>
          ({cfLocationId, liabilityCoverageId, locationId, rateTier: locationTier(locationId)})
      );
      const cfParams = isGl ? {} : {policyCommercialCFId, policyCFId, jurisdictionCFId};
      const params = {
        quoteId, policyId, locations,
        policyCommercialGLId, policyGLId, jurisdictionGLId, otherFlag,
        ...cfParams
      };
      if (otherReferralDetails) {
        params.propertyBag = { otherReferralDetails };
      }
      return this.oneShield('updateRateTier', params, {savingFlag: true});
    },
    responseStub({error = null, operation}){
      let response = {
        requestPayload: { operation },
        hasErrors: !!error
      };
      response.error = lookupError(response);
      return {
        response
      };
    }
  },
  created(){
    this.$emit('setComponentRef', {vm: this});
  },
  beforeDestroy(){
    //eventbus.$off('enterKey');
    this.isMounted = false;
  },
  mounted() {
    this.isMounted = true;
    /*if (this.fieldsPage) {
      this.initStep(this.fieldsPage);
    }*/
    this.delayRender = true;

  }
};
