<template>
  <div
    id="app" class="vh-100 mh-100"
    :style="{ backgroundColor: appBg }" data-app
    @keyup="userInput" @mouseup="appMouseUp"
    @keypress.enter="busEmit('enterKey')"
  >
    <b-alert
       :variant="alert.level"
       v-if="alert && systemAlertStatuses.includes(alert.level)" :show="true" :dismissible="alert.level==='info'" class="app-message">
      <i class="fas mr-2" :class="{'fa-exclamation-triangle':alert.level==='warning','fa-info-circle':alert.level==='info'}"></i>
      <strong v-if="alert.title" class="mr-2">{{ alert.title }}:</strong>
      {{ alert.message }}
    </b-alert>
    <nav-bar/>
    <maintenance v-if="maintenance" :title="alert.title" :message="alert.message"/>
    <router-view v-else />
    <simple-modal />
    <progress-toast/>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import SimpleModal from '@/components/modal/simple-modal';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import eventbus from '@/lib/eventbus';
import NavBar from '@/components/util/nav-bar';
import * as localForage from 'localforage';
import ProgressToast from '@/components/util/progress-toast';
import Maintenance from '@/components/util/maintenance';
import {portalEvents} from '@/lib/mix';
import {quotemix} from '@/lib/quotemix';

Vue.use(PerfectScrollbar);
//LIO2021
export default {
  name: 'lio-portal',
  data: () => {
    let tokenResolver;
    let tokenPromise = new Promise(res => tokenResolver = res);
    return {
      awaitingSession: false,
      envChanged: false,
      sessionRetries: 0,
      retryPeriod: 1000,
      tokenPromise, tokenResolver,
      systemAlertStatuses: ['info', 'warning'],
      systemAlerts: [],
      alert: null
    };
  },
  components: {Maintenance, ProgressToast, NavBar, SimpleModal },
  computed: {
    ...mapState(['isAuthenticated', 'appBg', 'storeInit', 'osUser', 'sessionStart', 'lastUserInput', 'okta', 'sessionTimeoutPeriod']),
    maintenance(){
      return this.alert && this.alert?.level === 'maintenance';
    },
    curPath(){
      return this.$route.path;
    },
    validOSSession(){
      let {sessionId, sessionStart, sessionTimeoutPeriod} = this;
      let now = Date.now();
      return (!this.envChanged && sessionId && (now - sessionStart) < sessionTimeoutPeriod);
    }
  },

  methods: {
    ...mapMutations(['setAny', 'userInput', 'setOSUser']),
    ...mapActions(['init', 'bindToAuthState', 'logout']),
    ...mapActions('getQuote', ['initStore']),
    pollAlerts () {
      this.lioApi('listSystemAlerts', null, null).then(result => {
        let pollInterval = 120000;
        if (result?.response.length > 0) {
          this.alert = this.getActiveAlert(result.response);
          const expiresInMs = new Date(this.alert?.endAt).getTime() - Date.now();
          pollInterval = expiresInMs < 120000 ? expiresInMs : pollInterval;
        } else {
          this.alert = null;
        }
        setTimeout(() => {
          this.pollAlerts();
        }, pollInterval);
      }).catch(result => {
        console.error('Error getting alerts', result);
      });
    },
    getActiveAlert(alerts) {
      const alertWeight = {
        info: 1,
        warning: 2,
        maintenance: 3
      };
      let highestPriority = 0;
      let activeAlert = null;
      alerts.forEach(a => {
        a.level = a.level.toLowerCase();
        const currentLevel = alertWeight[a.level];
        const active = a.endAt ? Date.now() > new Date(a.startAt) && Date.now() < new Date(a.endAt) : Date.now() > new Date(a.startAt);
        const isHeavier = active && (currentLevel > highestPriority || currentLevel === highestPriority && a.endAt < activeAlert.endAt);
        if (isHeavier) {
          activeAlert = a;
          highestPriority = currentLevel;
        }
      });
      return activeAlert;
    },

    appMouseUp(){
      this.userInput();
      eventbus.$emit('appMouseup');
    },
    busEmit(e, args){
      eventbus.$emit(e, args);
    },
    createSession(){

      if (this.awaitingSession || !this.storeInit || !this.isAuthenticated){
        return;
      }

      let {sessionId, sessionStart, sessionTimeoutPeriod} = this;

      let now = Date.now();

      if (!this.envChanged && sessionId && (now - sessionStart) < sessionTimeoutPeriod){
        console.log('cached session still valid. Refreshing');
        eventbus.sessionId = sessionId;
        this.oneShield('jurisdictionList').then(({response}) => {
          if (!response.error) {
            this.setAny({sessionStart: new Date().valueOf()});
          }else {
            eventbus.sessionId = null;
            this.setOSUser({sessionId: null});
            this.newSession();
          }
        });

      } else{
        this.newSession();
      }

    },
    newSession(){

      let { storeInit, apiEnv, tokenPromise, AccessToken} = this;

      console.log({createSession: {apiEnv, storeInit, tokenPromise}});

      const {
        humanUserName, userName,
        password, partnerId
      } = this.osUser;

      const postWhenReady = () => {
        if (this.awaitingSession){
          return;
        }
        this.awaitingSession = true;

        this.oneShield('createSession', {
          apiEnv: this.apiEnv,
          OS_TOKEN: this.AccessToken,
          humanUserName,
          userName,
          password,
          partnerId
        }).then(response => {
          this.envChanged = false;

          if (response.hasErrors || response.errorCode){
            this.delayFn(() => {
              this.retryPeriod = this.retryPeriod * 7;
              this.awaitingSession = false;
              this.createSession();
            }, this.retryPeriod);
            return console.warn({sessionErr: response});
          }
          else {
            console.log({createdSession: response});
            this.delayFn(() => {
              this.awaitingSession = false;
            }, 5000);//prevent redundant calls when known good session exists

            this.envChanged = false;
            let osUser = this.osUser;
            osUser.sessionId = response.sessionId;
            this.setAny({sessionStart: Date.now()});
            this.setOSUser(osUser);

            //centralize logic and dumb down event bus for expired session queued reqs
            eventbus.OS_TOKEN = this.AccessToken;
            eventbus.sessionId = response.sessionId;
            eventbus.ready = true;
            eventbus.apiEnv = this.apiEnv;
            this.clickStreamEvent(portalEvents.Login, null, null);
            if (this.curPath === '/login'){
              this.$router.push('/');
            }
            this.cacheSession();


          }
        });
      };
      if (AccessToken){
        postWhenReady();
      }else {
        this.tokenPromise.then(() => {
          if (eventbus.awaitingSession || this.awaitingSession) {
            console.log('ignore redundant createSession call (already awaiting)', {
              eventbus: eventbus.awaitingSession, app: this.awaitingSession
            });
            return;
          }
          postWhenReady();
        });
      }
    },
    cacheSession(){
      let {sessionStart, osUser, lastUserInput} = this;
      if (!this.sessionId){
        return this.createSession();
      }
      if (!sessionStart){
        sessionStart = new Date().valueOf();
        lastUserInput = sessionStart;
      }
      localForage.setItem('osSession', {sessionStart, osUser, lastUserInput});
    },
    cacheToken(){
      let token = this.AccessToken;
      if (token){
        console.log({cacheToken: `...${token.substr(token.length - 10)}`});
        eventbus.OS_TOKEN = token;
        if(this.tokenResolver) {
          this.tokenResolver(token);
          this.tokenResolver = null;
        }
      }
    }
  },
  created(){
    this.$root.tokenPromise = this.tokenPromise;
    this.initStore({vm: this});
    eventbus.$on('refreshSession', (hard) => {
      if (this.awaitingSession || this.validOSSession){
        return;
      }
      if (hard){
        this.setOSUser({sessionId: null});
        console.log('hardRefresh session called. requesting new id');
        this.newSession();
      } else {
        this.createSession();
      }
    });
    eventbus.$on('invalidateSession', () => {
      eventbus.sessionId = null;
      eventbus.ready = false;
      let sessionId = null;
      let sessionStart = null;
      let lastUserInput = null;
      let osUser = null;
      this.setOSUser({sessionId});
      this.setRoot({sessionStart});
      localForage.setItem('osSession', {sessionStart, osUser, lastUserInput}).finally(() => {
        this.newSession();
      });
    });
  },
  mounted() {
    this.init().then(() => {
      this.bindToAuthState(this.$auth);
      this.pollAlerts();
      this.oneShield('getPropertyManagerList', {}, {parseList: true}).then(({response: {list}}) => {
        localForage.setItem('getPropertyManagerList', list);
      });
    });
  },
  watch: {
    apiEnv(env, old){
      if (!old || old === env){
        return;
      }
      if (this.storeInit){
        this.createSession();
      }
    },

    sessionStart(dateVal){
      if (dateVal){
        this.cacheSession();
      }
    },
    lastUserInput(dateVal){
      if (dateVal){
        this.cacheSession();
      }
    },
    validOSSession(valid){
      if (valid){
        eventbus.sessionId = this.sessionId;
        eventbus.ready = true;
        eventbus.awaitingSession = false;
      }
    },
    curPath(cur, prev){
      if (cur.includes('/login')){
        return;
      }
      localStorage.setItem('lastPath', JSON.stringify({cur, prev}));
    },
    AccessToken(token){
      if (token){
        this.cacheToken();
      }
    }
  },
  mixins: [quotemix]// pass "this" into initStore to share mixin with typedefs
};
</script>
<style lang="scss">
@import "https://use.fontawesome.com/releases/v5.8.2/css/all.css";
@import "assets/scss/main";//2406775509 associa northern ca
div#app {
  height: 100%;
  top: 0;
  .app-message{
    margin-bottom: 0;
    border-bottom: solid 1px $warning;
    z-index: 2;
  }
  .ps {

    .ps__rail-y {
      z-index: 3
    }
  }
}
</style>
