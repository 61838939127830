<template>
  <div class="d-flex login vh-100">
    <div class="flex-fill"></div>


    <div style="width:1000px" class="d-flex">
      <div style="width:420px !important;">
        <div class="m-5 ml-0">
          <logo/>
        </div>
        <p style="">
          Simple. Intuitive. Efficient.
        </p>
        <p style="">
          We understand that time is as precious as money.
          That’s why we take steps to protect both at every stage of our innovative, fast, and intuitive insurance buying experience.
          In just minutes, you can generate a bindable policy. Waiting for weeks and stacks of paperwork are officially obsolete!
        </p>
        <div class="d-flex">
          <div style="width:30%"></div>

          <div class="flex-fill agent-win">
            <div class="win"></div>
            <div class="circ"></div>
          </div>
          <div style="width:30%"></div>
        </div>
      </div>
      <div style="width:150px"></div>
      <div style="width:415px;height:100%">
        <valign-mid class="vh-100">

          <div class="l-form" v-if="!loggingIn">
            <div id="okta-signin-container" ref="oktaContainer"></div>

          </div>
          <progress-bar v-else>Authenticating...</progress-bar>
        </valign-mid>
      </div>
    </div>
    <div class="flex-fill"></div>
  </div>

</template>
<script>

import Logo from '@/components/svgs/logo';
import {lioTheme} from '@/lib/lioTheme';

//Okta related imports
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import sampleConfig from '../config';
import ValignMid from '@/components/util/valign-mid';
import localForage from 'localforage';
import ProgressBar from '@/components/util/progress-bar';
import {mapMutations, mapState} from 'vuex';
import eventbus from '@/lib/eventbus';
import { oktaAuth } from '@/router';
import {datadogLogs} from '@datadog/browser-logs';

export default {
  data: () => {
    return {
      loginType: 'agent',
      bg: lioTheme.lightGray,
      redirecting: false,
      invalid: false
    };
  },
  name: 'login',
  computed: {
    ...mapState(['isAuthenticated', 'loggingIn'])
  },
  methods: {
    ...mapMutations(['setRoot']),
    ...mapMutations('getQuote', ['resetSteps']),
    renderWidget(){

      this.widget = new OktaSignIn({
        /**
         * Note: when using the Sign-In Widget for an OIDC flow, it still
         * needs to be configured with the base URL for your Okta Org. Here
         * we derive it from the given issuer for convenience.
         */
        baseUrl: sampleConfig.oidc.issuer.split('/oauth2')[0],
        clientId: sampleConfig.oidc.clientId,
        redirectUri: sampleConfig.oidc.redirectUri,
        // eslint-disable-next-line no-undef
        logo: require('@/assets/logo.png'),
        i18n: {
          en: {
            'primaryauth.title': 'LIO',
            // Errors
            'error.username.required': 'Please enter a valid username',
            'error.password.required': 'Please enter a password',
            'errors.E0000004': 'Sign in failed!'
          }
        },
        authClient: oktaAuth,
        features: {
          idpDiscovery: true,
          registration: true
        },
        idpDiscovery: {
          requestContext: window.location.href
        },
        preSubmit: function (postData, onSuccess, onFailure) {
          // A Default form level error is shown if no error object is provided
          onFailure();
        }
      });
    },
    init(){

      this.resetSteps();
      let lastPath = localStorage.getItem('lastPath');
      if (lastPath){
        lastPath = JSON.parse(lastPath)?.cur;
      }else{
        lastPath = '/';
      }
      this.delayFn(() => {
        this.renderWidget();
        this.delayFn(() => {
          let container = this.$refs.oktaContainer;
          if (!container || container.innerHTML === ''){
            let authenticated = this.authState.isAuthenticated;
            if (!authenticated) {
              console.log('empty container. widget failed to render. reloading...');
              location.reload();
            }
          }
        }, 1000);

        this.widget.showSignInAndRedirect(
          {el: '#okta-signin-container'}
        ).catch((err) => {
          throw err;
        });
        this.$auth.isAuthenticated().then(isAuth => {
          if (isAuth) {
            if (this.$route.path !== lastPath) {
              datadogLogs.logger.info('Login page redirect', {lastPath, routerPath: this.$route.path});
              this.$router.replace({path: lastPath});
            }
          }
          else {
            this.$auth.token.getWithoutPrompt({
              responseType: 'id_token' // or array of types
            })
              .then((res) => {
                this.redirecting = true;
                const tokens = res.tokens;
                this.$auth.handleLoginRedirect(tokens);
              })
              .catch((err) => {
                // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
                if (err.errorCode === 'login_required') {
                  console.log('User is not logged in, showing login form');
                  try {
                    //auth.setOriginalUri(lastPath);
                    localForage.setItem('osSession', null);
                  }catch(ex){
                    console.warn('set original uri doing weird things');
                  }
                } else {
                  console.warn('Unexpected auth error:', err);
                }
              });
          }
        });

      });
    },
    renderError(error) {
      this.$bvToast.hide('auth-error');
      if (!error) {
        return;
      }
      const message = error === 'not-authorized' ?
        'You are not authorized to use this application' :
        'An unknown error has occurred. ';
      this.$bvToast.toast(message, {
        id: 'auth-error',
        title: 'Login Error',
        noAutoHide: true,
        variant: 'danger',
        solid: true,
        toastClass: 'not-auth-toast',
        toaster: 'b-toaster-bottom-right',
        appendToast: false
      });

    }
  },
  components: {ProgressBar, ValignMid, Logo},
  // HTTP header token = os_oneshield / $auth.getIdToken()
  mounted() {
    this.delayFn(() => {
      if (this.loggingIn) {
        this.setRoot({loggingIn: false});//todo: why is isAuthenticated true when auto-logged out;
        this.init();
      }
    }, 5000);
    document.title = 'Login - LIO Insurance';
    this.delayFn(() => {
      this.renderError(this.$route.query.err);
    }, 1);

    if (!this.loggingIn) {
      this.init();
    }
  },
  watch: {
    '$route.query.err'(err) {
      this.renderError(err);
    }
  },
  beforeDestroy() {
    // Remove the widget from the DOM on path change
    if (this.widget && this.widget.remove) {
      this.widget.remove();
    }
  }
};
</script>
<style lang="scss">
@use 'sass:color';
@import "../assets/scss/variables";

.login {
  p, p * {
    font-size: 1.4rem;
    line-height: 1.7;
  }

  /*p{
    margin-right:30%;
  }*/
  .login-type {
    background-color: color.scale($teal, $lightness: 50%);
    border-radius: 8px;

    .btn {
      width: 92px;
      border-radius: 8px !important;
    }

    .inactive {
      background-color: color.scale($teal, $lightness: 50%);
      border-color: color.scale($teal, $lightness: 50%);
    }
  }

  div.agent-win {
    margin: 50px 0;
    height: 280px;
    width: 280px;
    position: relative;

    .win {
      height: inherit;
      width: inherit;
      background-color: white;
      background-image: url(../assets/agent1_286x424.png);
      background-position: center bottom;
      background-size: contain;
      background-repeat: no-repeat;
      border-bottom-right-radius: 28%;
      box-shadow: 0 10px 30px $gray;
      position: relative;
      z-index: 1;
    }

    .circ {
      //content:'';
      position: absolute;
      height: 200px;
      width: 200px;
      border-radius: 50%;
      bottom: -77px;
      left: -100px;
      background-color: $teal;
      z-index: 0;
    }

  }

  .l-form {
    width: 450px;
    background: white;
    margin: 0 auto;
    box-shadow: 0 10px 30px $gray;
    border-bottom-right-radius: 18%;

    a {
      display: inline-block;
      margin: 0 5px;
    }
  }
}
.not-auth-toast{
  margin-bottom:-88px;
  pointer-events: auto;
}
</style>
