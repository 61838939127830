<template>
<b-modal title="Location Hazard Scores" v-model="showModal" size="lg" @hidden="scoresClosed">

  <perfect-scrollbar style="max-height:650px">
    <div v-if="hasOtherReferral">
        <h3>Other Referral</h3>
        <ul class="mt-0">
          <li v-for="(entry, i) in otherReferralTable" :key="entry.label" :style="{backgroundColor:i%2===0?'#eee':'#fff'}">
            <strong class="score-label">{{entry.label}}</strong>
            <span>{{entry.text}}</span>
          </li>
        </ul>
    </div>
    <b-button @click="rescore" style="float:right;border-radius: 18px" v-if="!scoring" class="mr-3 pr-4 pl-4">Re-Score All</b-button>
    <progress-bar v-if="scoring" class="m-5">Getting Location Hazard Scores</progress-bar>
    <div v-for="(loc,li) in locations" :key="loc.locationId" v-else>
      <h3>
        Location {{loc.num}}
      </h3>

      <a v-if="scores(loc)" target="maps" :href="`http://maps.google.com/maps?q=${scores(loc).latitude},${scores(loc).longitude}&ll=${scores(loc).latitude},${scores(loc).longitude}&z=17`">
        {{loc.address}}
        (lat: {{scores(loc).latitude}}, lng: {{scores(loc).longitude}})
      </a>
      <h4 class="mt-3">Hazard Scores</h4>
      <ul class="mt-0" style="list-style-type: none" v-if="scores(loc)">

        <li v-for="(key,j) in displayKeys" :key="li+key" :style="{backgroundColor:j%2===0?'#eee':'#fff'}">
          <strong class="score-label">{{ labelText(key) }}</strong>
          <div v-if="key.includes('commun')" class="d-inline-block">
            <span v-if="override === li" class="d-flex">

              <b-dropdown :text="gradeOverride ? `${gradeOverride}` : scores(loc, key).value">
                <b-dropdown-item v-for="grade in 10" :key="'grade'+grade" @click="gradeOverride = grade">{{ grade }}</b-dropdown-item>
                <b-dropdown-item @click="gradeOverride = 'Not Applicable'">Not Applicable</b-dropdown-item>
                <b-dropdown-item @click="gradeOverride = 'Unknown'">Unknown</b-dropdown-item>
              </b-dropdown>
              <b-input v-model="gradeDescription" placeholder="Reason" class="ml-2" style="width:400px"/>
              <b-button @click="updateCommunityScore(loc)">Update</b-button>
            </span>
            <span v-else>{{valueText(scores(loc, key))}}
              <a @click="overrideGrade(li, scores(loc, key).value)" :style="{color:lioTheme.coral}">Override</a>
            </span>
          </div>
          <span v-else>{{valueText(scores(loc, key))}}</span>

          <!--          {{scores(loc, key+'.value')}} ({{scores(loc,key+'.id')}})-->
        </li>
        <li style="background-color: #eee">
          <strong class="score-label">Hurricane Tier</strong>
          {{ loc.child('hurricaneTier').val }}
        </li>
        <li style="background-color: #fff" v-if="scores(loc,'crime')">
          <strong class="score-label">Crime Value</strong>
          {{ scores(loc,'crime').value }} ({{ scores(loc, 'crime').score }})
        </li>
        <li class="mt-3">
          <h4>Wind/Hail Deductible by Building Type:</h4>
          <ul>
            <li v-for="(building,bi) in loc.buildingsDef.children" :key="'b'+li+bi"
                :style="{backgroundColor:bi % 2===0?'#eee':'#fff'}">
              <strong class="score-label">{{building.child('name').val}}</strong>
              {{whDeductible(building,loc)}}
            </li>
          </ul>
        </li>

      </ul>

    </div>
  </perfect-scrollbar>
  <template #modal-footer>
    <div class="w-100">
      <b-button size="lg" variant="primary" @click="scoresClosed">Close</b-button>
    </div>
  </template>
</b-modal>
</template>

<script>
import {quotemix} from '@/lib/quotemix';
import ProgressBar from '@/components/util/progress-bar';
import {getChainedVal, titleCased} from '@/lib/util';
import {getWindHailDeductible, windHailDeductibleByConstructionType} from '@/lib/hydration-io';
import {windHailDeductible} from '@/lib/fields/field-constants';

export default {
  components: {ProgressBar},
  data: () => {
    return {
      scoring: false,
      override: null,
      gradeDescription: null,
      gradeOverride: null,
      displayKeys: [
        'terrorismTerritory', 'wildfireZipScore', 'wildfireScore',
        'tornadoScore', 'hailScore', 'straightLineWindScore',
        'sToRMi', 'finalSToRMiScore', 'windPoolScore',
        'distanceToCoast', 'rawFreezeScore', 'freezeScore', 'ppc',
        'bgITerritory', 'bgIITerritory', 'specialTerritory',
        'communityCRSGrade', 'floodZone'
      ],
      flatKeys: []
    };
  },
  computed: {
    otherReferralTable() {
      const details = this.otherReferralDetails;
      const yearsBuilt = details.yearsBuilt.filter(y => y  && y < 1990);
      const yearsBuiltReport = yearsBuilt.slice(0, 2).join(', ').concat(yearsBuilt.length > 3 ? ['...'] : []);
      const rentalExposureReport = details.locationUnitCounts.map(r => `${r.unitCtOwnerOccupied}/${r.unitCt}`).join(', ');
     return [
        {label: 'GL Only', text: `${details.referForGl ? 'Yes' : 'No'}`},
        {label: 'Years Built', text: `${details.referForYearsBuilt ? 'Yes' : 'No'} (${yearsBuiltReport})`},
        {label: 'Association By-Laws', text: `${details.referForAssocByLaws ? 'Yes' : 'No'}`},
        {label: 'PPC Values', text: `${details.referForPPCValues ? 'Yes' : 'No'} (${details.ppcValues.join(', ')})`},
        {label: 'Crime Scores', text: `${details.referForCrimeScores ? 'Yes' : 'No'} (${details.crimeScores.join(', ')})`},
        {label: 'Rental Exposure', text: `${details.referForRentalExposure ? 'Yes' : 'No'} (Owner Occupied/Total Units: ${rentalExposureReport})`},
        {label: 'Condo Association', text: `${details.referForCOA ? 'Yes' : 'No'}`}
      ];
    },
    otherReferralDetails() {
      return this.getOtherReferralDetails();
    },
    hasOtherReferral() {
      return this.otherReferralDetails.hasOtherReferral;
    },
    whDeductible(){
      return (b, l) => {
        const scores = l.child('scores').val;
        let id = getWindHailDeductible(b.dataTree, l, scores);
        let deduct = windHailDeductible.find(item => item.id === id);
        return this.valueText(deduct);
      };
    },
    scores(){
      return (l, chain) => {
        let scores = chain ?
            getChainedVal(chain, l.child('scores').val) :
            l.child('scores').val;
        if (chain && chain.includes('communityCRS') && this.existingOverride(l)){
          scores.override = this.existingOverride(l);
        }
        return scores;
      };
    },
    labelText(){
      let tc = s => {
        let map = {
          sToRMi: 'sToRMi Score',
          finalSToRMiScore: 'Final sToRMi Score',
          bgITerritory: 'BGI Territory',
          bgIITerritory: 'BGII Territory',
          communityCRSGrade: 'CRS Grade',
          ppc: 'PPC'
        };
        return map[s] ?? this.titleCased(s);
      };
      return lbl => {
        return tc(lbl);
      };
    },
    valueText() {
      return val => {
        if (this.isType.string(val)) {
          return val;
        }
        else if (val && val.value && val.id){
          if (val.override){
            return `${titleCased(val.value.toLowerCase())} (override: ${val.override.crsGrade?.value ?? 'Unknown'}, reason: ${val.override.crsGradeDescription})`;
          }
          return `${titleCased(val.value.toLowerCase())} (${val.id})`;
        }
      };
    },
    existingOverride(){
      return loc => {
        let existing = this.itemVal('quote.floodOverrides') ?? [];
        return existing.find(item => item.locationId === loc.child('locationId').val);
      };
    }
  },
  name: 'rescore-locations',
  methods: {
    overrideGrade(li, grade){
      this.gradeOverride = grade;
      this.gradeDescription = null;
      this.override = li;
    },
    updateCommunityScore(loc){
      let {gradeOverride, gradeDescription} = this;

      let floodOverrides = this.locations.filter(l => this.existingOverride(l) || l.child('locationId').val === loc.child('locationId').val)
          .map(l => {
        let {locationId} = l.dataTree;
        let crsGrade, crsGradeDescription;
        if (locationId === loc.child('locationId').val){
          if (gradeOverride === null){
            gradeOverride = 'Unknown';
          }
          crsGrade = {value: gradeOverride, id: gradeOverride};
          crsGradeDescription = gradeDescription;
        }
        else if (this.existingOverride(l)){
          let existing = this.existingOverride(l);
          let value = existing.crsGrade?.value;
          crsGrade = {value, id: value};
          crsGradeDescription = existing.crsGradeDescription;
        }
        if (!crsGrade){
          console.warn({locationId, cur: loc.child('locationId').val});
          debugger;
        }
        return {
          locationId,
          crsGrade,
          crsGradeDescription
        };
      });
      console.warn({floodOverrides});
      this.propStore(this.itemVal('quote.quoteId'), {floodOverrides});
      this.updateField({chain: 'quote.floodOverrides', val: floodOverrides});
      this.gradeOverride = null;
      this.crsGradeDescription = null;
      this.override = null;
    },
    scoresClosed() {
      this.$emit('scoresClosed');
    },
    rescore(){
      this.scoring = true;
      let {quoteId, policyId, customerId, jurisdictionId} = this.quoteData.quote;
      let locations = this.locations.map(loc => {
        let {locationId, locationHazardId} = loc.dataTree;
        let {street1, city, zipcode, stateCode, addressId} = loc.addressDef.val;
        let state = stateCode;
        let isMailingAddress = false;
        let addressObj = loc.addressObj;
        return {
          street1, city, state, zipcode,
          addressId, locationId, locationHazardId, isMailingAddress,
          addressObj
        };
      });

      let effectiveDate = this.itemFromChain('quote.created').apiVal;//yyyy-mm-dd string
      let evalLocParams = this.gl ? {
        addresses: locations.map(
            ({city, state, zipcode, street1}) =>
                ({city, state, zipCode: zipcode, street1}))
      } : {
        IDsAndLocations: {
          jurisdictionId, policyId, customerId,
          transactionId: quoteId,
          locations
        }
      };
      let glOrRenew = this.gl || this.itemVal('quote.policyTranType') === '8';
      let operation = `${glOrRenew ? 'getScored' : 'eval'}Locations`;

      this.oneShield(operation, {effectiveDate, ...evalLocParams}).then(async evalResult => {

        let scoredLocations = this.gl ? evalResult.response : evalResult.response.scoredLocations;

        if (!this.gl) {
          const {underwritingTriggers} = evalResult.response;
          this.updateField({
            chain: 'quote.underwritingTriggers',
            val: underwritingTriggers
          });

        }

        // TerrorismTerritory is at the policy level, so grab the first present value
        const terrorismTerritory = scoredLocations?.find(l => l.terrorismTerritory?.id)?.terrorismTerritory.id ?? '-1';
        this.updateField({
          chain: 'quote.scoredLocations',
          val: scoredLocations
        });

        this.updateField({
          chain: 'policy.terrorismTerritory',
          val: terrorismTerritory
        });

        await this.fetchQuoteDetail();

        let mappedScores = this.locations.map(l => l.scores);
        console.log({mappedScores});
        this.scoring = false;
      });
    }
  },
  mixins: [quotemix],
  props: ['showModal']
};
</script>

<style scoped lang="scss">
li {
  padding:1px 8px;
  strong.score-label {
    display: inline-block;
    width: 188px;
  }
}
</style>
